@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;600;700&family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif; /* Most text will be this font */
  min-height: 100vh;
  background: #f8fafb;
  min-width: 100vw;
}

#root {
  /* React root div */
  min-height: 100vw;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
}

.logo {
  font-family: "EB Garamond", serif; /* The logo will display this font */
  font-weight: 600;
}

.filterOptions {
  font-family: "EB Garamond", serif; /* The Make, Model etc will display this font */
}

/* Header */

.header {
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0px;
  top: 0px;
  background: #434344;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerRight {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  width: 450px;
  width: 450px;
  margin-left: 20px;
}

.logo {
  position: absolute;
  width: 82px;
  height: 44px;
  left: 24px;
  top: 8px;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  margin-right: 20px;
}

.headerText {
  /* position: absolute; */
  width: 89px;
  height: 29px;
  left: 1267px;
  top: 16px;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  color: #adadaf;
  text-decoration: none;
}

.headerDashboard {
  width: 89px;
  height: 29px;
  left: 1267px;
  top: 16px;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  color: #adadaf;
  text-decoration: none;
  /* margin-right: 10px;
  margin-left: 10px; */
}

.headerList {
  width: auto;
  height: 29px;
  left: 1267px;
  top: 16px;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  /* identical to box height */
  /* text-align: center; */
  color: #adadaf;
  text-decoration: none;
}

.headerAccount {
  /* position: absolute; */
  width: 30px;
  height: 30px;
  left: 1380px;
  top: 15px;
}

.logOutButton {
  margin-top: 3px;
  font-size: 25px;
  color: #adadaf;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px; /* adjust this value as needed */
}

/* Search bar styling */
.searchBar {
  display: flex;
  flex-direction: column;
  position: relative; /* Required for absolute positioning of dropdown */
  margin-left: 100px;
}

.searchInput {
  padding: 8px 12px; /* Padding inside the input */
  border: 1px solid #adadaf; /* Border color */
  border-radius: 4px; /* Rounded corners */
  outline: none; /* Remove focus outline */
  width: 600px; /* Width of the input */
  background-color: #434344;
  color: white;
  height: 40px;
}

.searchInput::placeholder {
  color: #adadaf; /* White placeholder text */
  font-weight: 100;
}

.searchInput:focus {
  /* border-color: #aaa; Border color on focus */
  color: white;
}

/* Dropdown list styling */
.searchDropdown {
  position: absolute;
  top: 100%; /* Position below the input */
  left: 0;
  right: 0;
  background-color: #fff; /* White background */
  border: 1px solid #ccc;
  border-radius: 4px; /* Rounded corners */
  max-height: 250px; /* Max height for overflow handling */
  /* overflow-y: auto; Scrollbar if needed */
  z-index: 100; /* Ensure it's above other elements */
  /* display: flex;
  align-items: center; */
  margin-left: 20px;
  width: 100%;
}

@media (min-width: 440px) {
  .searchDropdown {
    margin-left: 0; /* Remove margin-left */
  }
}

.searchDropdownItem {
  padding: 10px; /* Padding inside the dropdown items */
  cursor: pointer;
  transition: background-color 0.2s; /* Smooth transition for hover */
  display: flex;
  justify-content: space-between;
  width: 600px;
}

.searchDropdownItemLeft {
  display: flex;
  align-items: center;
}

.searchDropdownItem:hover {
  background-color: #f0f0f0; /* Light gray on hover */
}

.dealerSearchBarDropdownImg {
  max-width: 20px;
  max-height: 20px;
  margin-right: 10px;
}

/* Burger menu icon for mobile screens */
.burgerMenu {
  display: none;
  font-size: 30px;
  color: #adadaf;
  cursor: pointer;
}

/* .headerRight.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px; 
  right: 20px;
  background: #434344;
  padding: 20px;
  border-radius: 5px;
} */

.headerRight.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px; /* Below the header */
  right: 20px;
  background: #434344;
  padding: 20px;
  border-radius: 5px;
  left: 80%;
  width: 150px;
  z-index: 1000; /* Ensures it appears on top of other content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds a shadow for a layered effect */
}

/* Media Queries*/

/*
  For
  tablets
  and
  smaller
  screens
  (less than 768px wide)
  */
@media (max-width: 1245px) {
  .headerRight {
    display: none;
  }

  .headerRight.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Below the header */
    right: 20px;
    background: #434344;
    padding: 20px;
    border-radius: 5px;

    z-index: 1000; /* Ensures it appears on top of other content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds a shadow for a layered effect */
  }

  .headerRight.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Below the header */
    right: 20px;
    background: #434344;
    padding: 20px;
    border-radius: 5px;

    z-index: 1000; /* Ensures it appears on top of other content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds a shadow for a layered effect */
  }

  .burgerMenu {
    display: block; /* Show burger menu */
    margin-right: 20px;
  }

  .searchBar {
    width: 80%; /* Adjust width of search bar */
    margin-left: 0;
  }

  .searchInput {
    width: 80%; /* Full width input */
    margin-left: 60px;
    width: 80%; /* Full width input */
    margin-left: 60px;
  }

  .searchDropdownItem {
    width: 100%; /* Full width dropdown items */
  }
}

/* For mobile devices (less than 480px wide) */
@media (max-width: 480px) {
  .headerRight {
    display: none; /* Keep the headerRight hidden initially */
  }

  .headerRight.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Below the header */
    right: 20px;
    background: #434344;
    padding: 20px;
    border-radius: 5px;
    left: 45%;
    width: 150px;
    z-index: 1000; /* Ensures it appears on top of other content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds a shadow for a layered effect */
  }

  .logo {
    font-size: 24px; /* Adjust font size */
  }

  .searchBar {
    width: 70%; /* Adjust width of search bar */
  }

  .searchInput {
    width: 100%; /* Full width input for mobile */
    height: 35px; /* Adjust height */
  }

  .searchDropdownItem {
    font-size: 14px; /* Smaller font for dropdown items */
  }
}

/* For tablets and smaller screens (less than 768px wide) */
@media (max-width: 768px) {
  .searchBar {
    width: 60%; /* Shrinks the width of the search bar */
    margin-left: 0; /* Center it better on smaller screens */
  }

  .searchInput {
    height: 35px; /* Slightly reduce height for smaller screens */
  }
}
@media (max-width: 1000px) {
  .searchBar {
    width: 40%; /* Shrinks the width of the search bar */
    margin-left: 0; /* Center it better on smaller screens */
  }

  .searchInput {
    height: 35px; /* Slightly reduce height for smaller screens */
    width: 80%;
  }
}

/* For mobile devices (less than 480px wide) */
@media (max-width: 480px) {
  .searchBar {
    width: 40%; /* Further shrink the width for mobile devices */
  }

  .searchInput {
    height: 35px;
    margin-left: 20px;
    width: 100%;
  }
}

/* For very small screens (less than 320px wide) */
@media (max-width: 320px) {
  .searchBar {
    width: 30%; /* Reduce further for very small screens */
  }

  .searchInput {
    height: 30px; /* Adjust height for very small screens */
  }
}

/* Media Query to hide logo on smaller screens */
/* @media (max-width: 370px) {
/* @media (max-width: 370px) {
  .logo {
    display: none; /* Hides the logo when the screen width is 480px or smaller */

/* Users Table */
/* Users Table */

.tableOfUsers {
  border-collapse: collapse;
  margin: 20px auto;
  width: 100%;
  max-width: 1200px;
}

.tableOfUsers th,
.tableOfUsers td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.tableThead {
  background-color: #f5f5f5;
}

.userTableEmail {
  width: 20%;
}

.userTableTelephone {
  width: 15%;
}

.userTableFirstname {
  width: 15%;
}

.userTableLastname {
  width: 15%;
}

.userTableRoles {
  width: 10%;
}

.userTableCompanyName {
  width: 15%;
}

.userTableLocation {
  width: 10%;
}

.userTableEdit {
  width: 5%;
}

.errmsg {
  color: red;
}

.table__row.user {
  background-color: #fff;
  border: 1px solid #ddd;
}

.tableCell {
  padding: 10px;
}

.tableCellInactive {
  background-color: #f0f0f0;
}

.table__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.table__button:hover {
  color: #333;
}

.tableCell,
.userTableEdit {
  text-align: center;
}

.userTableRoles {
  text-align: left;
  padding-left: 20px;
}

.userTableEmail,
.userTableTelephone,
.userTableFirstname,
.userTableLastname,
.userTableCompanyName,
.userTableLocation {
  text-align: left;
  padding-left: 10px;
}

.userTableEdit {
  padding: 0;
}

/* Vehicle Card */

.vehicleListErrMsg {
  color: red;
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
}

.vehicleCardImg {
  position: absolute;
  width: 324px;
  height: 208px;
  top: 0;
  left: 0;
  border-radius: 17px 17px 0px 0px;
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

.vehicleCardMakeModel {
  position: absolute;
  top: 228px;
  left: 13px;
  width: 254px;
  height: 40px;
  margin: 0;
  padding: 0;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #000;
}

.vehicleCardPricePerDay {
  position: absolute;
  top: 290px;
  left: 13px;
  width: 76px;
  height: 29px;
  margin: 0;
  padding: 0;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #0561fc;
}

.vehicleCardMileageLimit {
  position: absolute;
  top: 336px;
  left: 13px;
  width: 84px;
  height: 29px;
  margin: 0;
  padding: 0;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #0561fc;
}

.vehicleCardDeposit {
  position: absolute;
  top: 382px;
  left: 13px;
  width: 116px;
  height: 29px;
  margin: 0;
  padding: 0;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #0561fc;
}

.location {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: absolute;
  bottom: 35px;
  right: 15px;
  width: 59px;
  height: 23px;
  margin: 0;
  padding: 0;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000;
}

.locationIcon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  flex-shrink: 0;
}

.vehicleCard {
  position: relative;
  width: 324px;
  height: 542px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 17px;
  margin-left: 10px;
}

.vehicleCardPricePerDay span {
  color: #93a3ab;
}

.vehicleCardMileageLimit span {
  color: #93a3ab;
}

.vehicleCardDeposit span {
  color: #93a3ab;
}

.vehicleCard .vehicleCardProfileImage {
  padding-right: 70px;
  padding-bottom: 10px;
  /* image 2 */

  width: 220px;
  height: 85px;
}

/* Vehicle List */

.vehicleListGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-left: 350px;
  min-height: 1300px;
}

@media (max-width: 1290px) {
  .vehicleListGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 910px) {
  .vehicleListGrid {
    grid-template-columns: 1fr;
  }
}

/* Add New User */

.newUserForm {
  position: absolute;
  width: 500px;
  /* height: 1450px; */
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow-y: scroll; */
  min-height: 1050px; /* Set a fixed height to limit the container height */
  padding-bottom: 30px;
}

.newDealershipForm {
  position: absolute;
  width: 500px;
  min-height: 1050px;
  padding-bottom: 30px;
  left: 475px;
  top: 100px;
  background: #ffffff;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  /* height: 1450px; */
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  /* overflow-y: scroll; */
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
}

.editUserForm {
  position: absolute;
  width: 500px;
  /* height: 1100px; */
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow-y: scroll; */
  min-height: 1050px; /* Set a fixed height to limit the container height */
  padding-bottom: 30px;
}

.signUp {
  /* width: 282px; */
  width: auto;
  height: auto;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 65px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
}

.form__input {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  width: 343px;
  height: 49px;
  background-color: #f8fafb;
  border: none;
  display: block;
}

.passwordInstructions {
  padding-bottom: 7px;
}

.passwordConfirmText {
  padding-bottom: 7px;
}

.form__input::file-selector-button {
  /* Filter */

  color: #0561fc;
  width: 100px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #dff0ff;
  outline: none;
  border: none;
  box-shadow: none;
  color: #0561fc;
  text-align: center;
  font-family: Spartan;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.27px;
  padding-bottom: 5px;
  cursor: pointer;
}

.form__action-buttons {
  display: flex;
  justify-content: space-between;
  width: 343px;
  margin-top: 10px;
}

.form__action-buttons button {
  background-color: #dff0ff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  transition: background-color 0.3s ease-in-out;
  flex: 1;
  margin-right: 10px;
}

.errmsg {
  color: red;
  font-weight: bold;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.form__input::placeholder {
  color: #c1cace;
}

.form__input--incomplete {
  border-color: red;
}

/* this is for the roles  */

.form__select {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 343px;
}

.form__select--incomplete {
  border-color: red;
}

/*  */

.form__action-buttons button:hover {
  background-color: #0561fc;
}

.form__action-buttons button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.form__label--visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

@media (max-width: 515px) {
  .newUserForm {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .editUserForm {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 400px) {
  .newUserForm .inputFields {
    width: 300px;
  }

  .newUserForm .inputFields input {
    width: 300px;
  }

  .newUserForm .form__action-buttons {
    width: 300px;
  }

  .editUserForm .inputFields {
    width: 300px;
  }

  .editUserForm .inputFields input {
    width: 300px;
  }

  .editUserForm .form__action-buttons {
    width: 300px;
  }
}

/* New Vehicle Form*/

.newVehicleForm {
  background: #f8fafb;
}

.newVehicleForm h1 {
  position: absolute;
  width: 150px;
  height: 18px;
  left: 327px;
  top: 108px;

  font-family: "Spartan";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #000000;
}

.newVehicleFormDivs {
  width: 1046px;
  margin-left: 280px;
  margin-top: 60px;
}

.newVehicleFormDivs
  .newVehicleFormVehicleDetails
  .newVehicleFormVehicleDetailsColumn
  .newVehicleFormPTag {
  padding-top: 20px;
}

.newVehicleFormVehicleDetails,
.newVehicleFormFinancialDetailis,
.newVehicleFormAvailabilityDetailis,
.newVehicleFormDeliveryDetails,
.newVehicleFormNotes {
  width: 100%;
  border-radius: 17px;
  background: #fff;
  min-height: 200px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.newVehicleFormImagesCollection {
  width: 700px;
  height: 450px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.imagesCollectionNewVehicle img {
  width: 700px; /* Set fixed width */
  height: 450px; /* Set fixed height */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

/* .newVehicleFormImagesCollection .imagesCollectionNewVehicle .arrow-left {
  z-index: 0;
} */

.newVehicleFormDeliveryDetails p {
  color: #0561fc;
  font-family: "EB Garamond";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 20px;
  padding-top: 15px;
}

.newVehicleFormVehicleDetails .newVehicleFormVehicleDetailsColumn p {
  color: #0561fc;
  font-family: "EB Garamond";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 20px;
}

.newVehicleFormFinancialDetailisColumn {
  color: #0561fc;
  font-family: "EB Garamond";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 20px;
}

.newVehicleFormVehicleDetails {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Create 4 equal-width columns */
  gap: 1rem; /* Adjust spacing between columns as needed */
}

.newVehicleFormFinancialDetailis,
.newVehicleFormAvailabilityDetailis {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create 4 equal-width columns */
  gap: 0.5rem; /* Adjust spacing between columns as needed */
}

.newVehicleFormDeliveryDetails {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create 2 equal-width columns */
  gap: 0.5rem; /* Adjust spacing between columns as needed */
}

.newVehicleFormDeliveryDetails
  .newVehicleFormDeliveryDetailsColumn
  .newVehicleFormCheckboxPTag {
  color: #000000;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}

.newVehicleFormDeliveryDetailsColumn .maxDeliveryMileage {
  color: #000000;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
  margin-top: 40px;
}

.newVehicleFormDeliveryDetailsColumn .deliveryCostPerMile {
  color: #000000;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}

.newVehicleFormAvailabilityDetailisColumn {
  padding-top: 20px;
  color: #0561fc;
  font-family: "EB Garamond";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 20px;
}

.newVehicleFormAvailabilityDetailis
  .newVehicleFormAvailabilityDetailisColumn
  .vehicleStartTimesLabel {
  color: #000000;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}

.newVehicleFormSameDayReturnAndStart {
  margin-top: 20px;
}

.newVehicleFormSameDayReturnAndStart p {
  font-size: 16px;

  color: black;
  font-weight: 600;
  margin-top: 10px;
}

.newVehicleFormSameDayReturnAndStartCheck {
  margin-bottom: 10px;
  margin-top: 10px;
}

.custom_tooltip--VehicleForms {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
}

.newVehicleFormNotes p {
  color: #0561fc;
  font-family: "EB Garamond";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.newVehicleFormNotesInput {
  width: 100%;
  height: 240px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f8fafb;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
}

.newVehicleFormCheckboxPTag {
  font-size: 16px;
  margin-left: 20px;
  color: black;
  font-weight: 600;
}

.newVehicleFormVehicleDetails
  .newVehicleFormVehicleDetailsColumn
  .newVehicleFormDrivetrain {
  font-size: 16px;
  margin-left: 20px;
  color: black;
  font-weight: 600;
}

/* .form__input__vehicle__notes {
  width: 100%;
  height: 100%;
  background: #f8fafb;
  border-radius: 10px;
  border: none;
  margin-left: 20px;
} */

.form__input__vehicle__notes {
  width: 100%; /* Set the width to 100% of the parent container */
  height: 100%;
  background: #f8fafb; /* Light background color */
  border-radius: 10px; /* Rounded corners */
  border: none; /* Remove the default border */
  margin-left: 20px; /* Add left margin */
  padding: 10px; /* Add padding inside the textarea */
  font-size: 16px; /* Set font size */
  resize: vertical; /* Allow vertical resizing only */
  box-sizing: border-box; /* Include padding and border in element's width and height */
  border: none;
  outline: none;
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.form__input__images {
  width: 150px;
  height: 30px;
  /* margin-right: 170px; */
  margin-bottom: 15px;
  background: #f8fafb;
  border-radius: 10px;
  margin-top: 23px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.form__input__images::file-selector-button {
  /* Filter */

  color: #0561fc;
  width: 80px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #dff0ff;
  outline: none;
  border: none;
  box-shadow: none;
  color: #0561fc;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.27px;
  padding-bottom: 5px;
  cursor: pointer;
}

.form__input__vehicle {
  width: 150px;
  height: 30px;
  /* margin-right: 170px; */
  margin-bottom: 15px;
  background: #f8fafb;
  border-radius: 10px;
  margin-top: 23px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.form__input__vehicle::placeholder {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;
  text-align: center;

  color: #93a3ab;
}

.vehicleFormInputFields {
  position: absolute;
  width: 1046px;
  height: 750px;
  left: 327px;
  top: 144px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 17px;
}

.notesInputField {
  margin-top: 60px;
  flex-basis: 100%;
  width: 1046px;
  height: 300px;
  background: ffffff;
  border-radius: 17px;
}

.newVehicleFormSaveBtn {
  position: absolute;
  left: 1250px;
  top: 100px;
  display: flex;
  align-items: center;
}

.editVehicleFormSaveBtn {
  position: absolute;
  left: 1150px;
  top: 100px;
  display: flex;
  align-items: center;
}

.saveBtn,
.delBtn {
  width: 100px;
  height: 25px;
  border-radius: 5px;
  border: none;
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #000000;
  cursor: pointer;
}

.saveBtn {
  background: #92fe9d;
}

.saveBtn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.delBtn {
  background: red;
  margin-right: 20px;
  order: -1;
}

/* sign in */

.login {
  position: absolute;
  width: 500px;
  height: 450px; /* Keep the height fixed */
  left: 50%; /* Center horizontally */
  top: 20%; /* Adjust this value to place it near the top */
  transform: translateX(-50%); /* Center horizontally by shifting left */
  background: #ffffff;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form__submit-button {
  background-color: #dff0ff;
  border: none;
  border-radius: 5px;
  color: #0561fc;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  transition: background-color 0.3s ease-in-out;
  flex: 1;
  margin-right: 10px;
  width: 343px;
}

.login label {
  color: #0561fc;
  font-weight: bold;
}

.form__input--Login {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  width: 343px;
  height: 49px;
  background-color: #f8fafb;
  border: none;
  display: block;
  margin-top: 5px;
}

.form__persist-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  margin-top: 20px;
}

.form__persist-label {
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: #0561fc;
  font-weight: bold;
}

.form__persist-text {
  /* margin-left: 5px; */
  text-align: center;
}

@media (max-width: 535px) {
  .login {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .login .form {
    max-width: 350px;
  }
}

@media (max-width: 400px) {
  .login {
    width: 350px;
  }

  .login .form__input--Login {
    max-width: 300px;
  }

  .login .form__submit-button {
    max-width: 300px;
  }
}

/* Dashboard Side Bar*/

.dashSideBar {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 60px;
  bottom: 0%;
  background: #ffffff;
  width: 282px;
  height: 3200px;
  display: flex;
  flex-direction: column;
}

.dashSideBarAccount {
  background: #e9f0f3;
  border-radius: 8px;
  width: 214px;
  height: 53px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-left: 34px;
  margin-top: 13px;
  position: relative;
}

.dashSideBarAccount p {
  margin-right: 5px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;

  color: #000000;

  mix-blend-mode: normal;
}

.dashSideBarAnalytics {
  background: #ffffff;
  border-radius: 8px;
  width: 214px;
  height: 53px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-left: 34px;
  margin-top: 13px;
}

.dashSideBarAnalytics p {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: -0.01em;
  color: #aeb9be;
  margin-left: 15px;
}

.analyticsChart {
  color: #aeb9be;
}

.angleRightIcon {
  position: absolute;
  right: 15px;
  color: black;
}

/* New Booking Form */

.newBookingForm .modelAndModelVariantBookingForm {
  color: black;
  font-family: Spartan;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: left !important; /* Force left alignment */
  margin-left: 24px !important; /* Create a gap of 24 pixels from the left */
}

.vehicleDetailsBookingForm {
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack child elements vertically */
  width: 1380px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 24px;
  margin-top: 22px;
  padding: 16px 11px; /* 16px from top, 11px from sides */
  box-sizing: border-box; /* Ensure padding is included in width/height */
  margin-bottom: 22px;
}

/* @media (max-width: 480px) {
  .newBookingForm {
    max-width: 400px;
  }
} */

.vehicleDetailsBookingForm p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
}

/* Add a class to the table container for better specificity */
.table-container {
  margin-left: 11px; /* Margin for the first "technical details" th */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  width: 125px; /* Set a width for each th */
  text-align: left; /* Align th text to the left */
  color: #0561fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

td {
  padding-top: 14px; /* Add space between td and th above */
  color: #93a3ab;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}

/* Add more specific selectors as needed to target this specific table */

/* Add margin to the p tag */
.table-container p {
  margin-bottom: 38px; /* Margin between p tag and th */
}

.customerDetailsBookingForm {
  display: flex; /* Use flexbox to align the content */
  flex-direction: column; /* Stack child elements vertically */
  width: 1380px;
  height: 450px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 24px;
  padding-top: 20px; /* 20px gap from the top */
  padding-left: 19px; /* 19px gap from the left */
  box-sizing: border-box; /* Ensure padding is included in width/height */
}

.customerDetailsBookingForm p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
}

.customerDetailsBookingForm {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns */
  gap: 15px; /* Gap between grid items */
}

.customerDetailsBookingFormEdit {
  display: flex; /* Use flexbox to align the content */
  flex-direction: column; /* Stack child elements vertically */
  width: 1380px;
  height: 600px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 24px;
  padding-top: 20px; /* 20px gap from the top */
  padding-left: 19px; /* 19px gap from the left */
  box-sizing: border-box; /* Ensure padding is included in width/height */
}

.customerDetailsBookingFormEdit p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
}

.customerDetailsBookingFormEdit {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns */
  gap: 15px; /* Gap between grid items */
}

.customerDetailsBookingFormEdit span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}
/* Visually hide labels */
.form__label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Style the input fields */
.form__input__booking {
  width: 150px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;
}

.form__input__booking__chauffeur {
  width: 150px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;
  margin-left: -50px;
}

.form__input__booking__chauffeurDetails {
  width: 300px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;
  resize: none; /* Prevent resizing if it's a textarea */
  overflow-wrap: break-word; /* Ensure text wraps within the input field */
  word-break: break-word; /* Handle long words */
  text-align: left; /* Align text to the left */
  vertical-align: top; /* Align text to the top */
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.form__input__booking__chauffeurDetails::placeholder {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

/* Show the placeholder text initially */
.form__input__booking::placeholder {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: center; /* Center placeholder text horizontally */
}

.sectionHeader {
  grid-column: 1 / span 4; /* Span all three columns */
}

/* Other CSS styles for your .personalDetailsBookingForm, .drivingLisenceDetails, and .passportDetails */

.personalDetailsBookingForm {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  margin-left: 21px;
}

.customLocationNewBookingForm {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 10px;
}

.personalDetailsBookingForm p {
  margin-bottom: 24px; /* Add a 24px gap below the <p> tag */
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.personalDetailsBookingForm span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 5px;
}

.drivingLisenceDetails span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 5px;
}

.passportDetails span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}

.extraDetailsConnection span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}

.durationDetails span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}

.personalDetailsAdditionalDriver span,
.drivingLisenceDetailsAdditionalCustomer span,
.passportDetailsAdditionalDriver span,
.extraDetailsConnection span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}

.drivingLisenceDetailsAdditionalCustomer span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}

.personalDetailsBookingForm label,
.personalDetailsBookingForm input,
.personalDetailsBookingForm select {
  margin-bottom: 15px; /* Add a 15px gap between elements */
}

.drivingLisenceDetails {
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

.drivingLisenceDetails p {
  margin-bottom: 24px; /* Add a 24px gap below the <p> tag */
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.drivingLisenceDetails label,
.drivingLisenceDetails input,
.drivingLisenceDetails select {
  margin-bottom: 15px; /* Add a 15px gap between elements */
}

.extraDetailsConnections {
  display: flex;
  flex-direction: column;
}

.extraDetailsConnections p {
  margin-bottom: 24px; /* Add a 24px gap below the <p> tag */
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.extraDetailsConnections label,
.extraDetailsConnections input,
.extraDetailsConnections select {
  margin-bottom: 15px; /* Add a 15px gap between elements */
}

.passportDetails {
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

.passportDetails p {
  margin-bottom: 24px; /* Add a 24px gap below the <p> tag */
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.passportDetails label,
.passportDetails input,
.passportDetails select {
  margin-bottom: 15px; /* Add a 15px gap between elements */
}

/* Style for the booking details container */
.bookingDetails {
  width: 1380px;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-top: 30px;
  margin-left: 27px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* 1/4, 1/4, and 2/4 columns */
  grid-gap: 15px;
  align-items: start; /* Align items to the top */
  padding-bottom: 20px;
}

/* Style for the section header */
.sectionHeaderBookingDetails {
  grid-column: span 4; /* Span all three columns */
  margin-bottom: 14px; /* Add margin to the bottom */
  height: 0px;
}

/* Style for pick-up and drop-off location */
.pickUpAndDropOffLocation {
  grid-column: 1 / span 1;
  display: grid;
  grid-gap: 15px;
  /* By default, no padding-top */
}

.custom-padding {
  padding-top: 35px; /* Apply padding-top only when the Custom option is selected */
}

/* Style for the chauffeur section */
.chauffeurDetails {
  grid-column: 2 / span 1; /* Span the second column (25%) */
  display: grid;
  grid-gap: 15px;
  align-items: start; /* Align items to the top */
  margin-left: 21px; /* Adjust left margin */
}

/* Style for the duration details */
.durationDetails {
  grid-column: 3 / span 1; /* Span the third and fourth columns (50%) */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.startEndDetails {
  grid-column: 4 / span 1; /* Span the third and fourth columns (50%) */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

/* Style for the checkbox when checked */
input[type="checkbox"]:checked + label::before {
  background-color: #0561fc;
  border: 2px solid #0561fc;
}

.chauffeurDetails p {
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.sectionHeaderBookingDetails p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-top: 20px;
  margin-left: 19px;
}

.pickUpAndDropOffLocation {
  margin-left: 21px;
}

/* Style for the duration details container */
.durationDetails {
  display: flex;
  flex-direction: column;
}

.durationInputFields {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.startEndInputFields {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pickUpAndDropOffLocation p {
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.durationDetails p {
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.startEndDetails p {
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.additionalDriverBookingForm {
  display: flex; /* Use flexbox to align the content */
  flex-direction: column; /* Stack child elements vertically */
  width: 1380px;
  height: 450px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 24px;
  padding-top: 0px; /* 20px gap from the top */
  padding-left: 19px; /* 19px gap from the left */
  box-sizing: border-box; /* Ensure padding is included in width/height */
  margin-top: 30px;
}

.additionalDriverBookingFormEdit {
  display: flex; /* Use flexbox to align the content */
  flex-direction: column; /* Stack child elements vertically */
  width: 1380px;
  height: 600px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 24px;
  padding-top: 0px; /* 20px gap from the top */
  padding-left: 19px; /* 19px gap from the left */
  box-sizing: border-box; /* Ensure padding is included in width/height */
  margin-top: 30px;
}

.sectionHeaderAdditionalDriver {
  grid-column: span 2; /* Span both columns */
  margin-bottom: 14px; /* Add margin to the bottom */
  height: 0px;
}

.sectionHeaderAdditionalDriver {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-top: 20px;
  margin-left: 19px;
}

.additionalDriverBookingForm {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns */
  gap: 15px; /* Gap between grid items */
}

.additionalDriverBookingFormEdit {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns */
  gap: 15px; /* Gap between grid items */
}

.extraDetailsConnectionsAdditionalDriver span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}

.sectionHeaderAdditionalDriver {
  grid-column: 1 / span 4; /* Span all three columns */
}

/* Personal Details Styling for Additional Driver */
.personalDetailsAdditionalDriver {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  margin-left: 21px;
}
.personalDetailsAdditionalDriver p {
  margin-bottom: 24px; /* Add a 24px gap below the <p> tag */
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.personalDetailsAdditionalDriver label,
.personalDetailsAdditionalDriver input,
.personalDetailsAdditionalDriver select {
  margin-bottom: 15px; /* Add a 15px gap between elements */
}

.personalDetailsAdditionalDriverEdit {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  margin-left: 21px;
}

.personalDetailsAdditionalDriverEdit p {
  margin-bottom: 24px; /* Add a 24px gap below the <p> tag */
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.personalDetailsAdditionalDriverEdit label,
.personalDetailsAdditionalDriverEdit input,
.personalDetailsAdditionalDriverEdit select {
  margin-bottom: 15px; /* Add a 15px gap between elements */
}

.personalDetailsAdditionalDriverEdit span,
.drivingLisenceDetailsAdditionalCustomer span,
.passportDetailsAdditionalDriver span,
.extraDetailsConnection span {
  color: black;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 0px;
}

/* Driving License Details Styling for Additional Driver */
.drivingLisenceDetailsAdditionalCustomer {
  display: flex;
  flex-direction: column; /* Stack items vertically */
}
.drivingLisenceDetailsAdditionalCustomer p {
  margin-bottom: 24px; /* Add a 24px gap below the <p> tag */
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.drivingLisenceDetailsAdditionalCustomer label,
.drivingLisenceDetailsAdditionalCustomer input,
.drivingLisenceDetailsAdditionalCustomer select {
  margin-bottom: 15px; /* Add a 15px gap between elements */
}

.extraDetailsConnectionsAdditionalDriver {
  display: flex;
  flex-direction: column;
}

.extraDetailsConnectionsAdditionalDriver p {
  margin-bottom: 24px; /* Add a 24px gap below the <p> tag */
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.extraDetailsConnectionsAdditionalDriver label,
.extraDetailsConnectionsAdditionalDriver input,
.extraDetailsConnectionsAdditionalDriver select {
  margin-bottom: 15px; /* Add a 15px gap between elements */
}

/* Passport Details Styling for Additional Driver */
.passportDetailsAdditionalDriver {
  display: flex;
  flex-direction: column; /* Stack items vertically */
}
.passportDetailsAdditionalDriver p {
  margin-bottom: 24px; /* Add a 24px gap below the <p> tag */
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.passportDetailsAdditionalDriver label,
.passportDetailsAdditionalDriver input,
.passportDetailsAdditionalDriver select {
  margin-bottom: 15px; /* Add a 15px gap between elements */
}

/* Style for the payment details container */
.paymentDetails {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four equal columns */
  grid-gap: 15px;
  width: 1380px;
  /* height: 550px; */
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 24px;
  padding-top: 0px; /* 20px gap from the top */
  padding-left: 19px; /* 19px gap from the left */
  box-sizing: border-box; /* Ensure padding is included in width/height */
  margin-top: 30px;
  margin-bottom: 40px;
}

/* Style for the section header */
.paymentDetailsHeader {
  grid-column: span 4; /* Span all four columns */
  margin-bottom: 0px;
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-top: 20px;
  margin-left: 19px;
}

/* Style for vehicleOption, dealershipOption, orderNumber, and totalPrice */
.vehicleOption,
.dealershipOption,
.orderNumber,
.totalPrice {
  display: flex;
  flex-direction: column;
}

.vehicleOption p,
.dealershipOption p,
.orderNumber p,
.totalPrice p {
  margin-bottom: 24px;
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

/* Style for input/select elements */
.vehicleOption label,
.dealershipOption label,
.orderNumber label,
.totalPrice label,
.vehicleOption input,
.dealershipOption select,
.orderNumber input,
.totalPrice input {
  margin-bottom: 15px;
}

/* Add custom styles for the specific elements if needed */

.vehicleOption {
  margin-left: 24px;
}

/* Style for the insurance details container */
.insuranceDetails {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four equal columns */
  grid-gap: 15px;
  width: 1380px;
  height: 250px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 24px;
  margin-top: 30px;
}

/* Style for the section header */
.insuranceDetailsHeader {
  grid-column: span 4; /* Span all four columns */
  margin-bottom: 14px;
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-top: 20px;
  margin-left: 19px;
}

/* Style for insuranceCompany, policyNumber, validFrom, and validUntil */
.insuranceCompany,
.policyNumber,
.validFrom,
.validUntil {
  display: flex;
  flex-direction: column;
}

.insuranceCompany p,
.policyNumber p,
.validFrom p,
.validUntil p {
  margin-bottom: 24px;
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

/* Style for label and input elements */
.insuranceCompany label,
.policyNumber label,
.validFrom label,
.validUntil label,
.insuranceCompany input,
.policyNumber input,
.validFrom input,
.validUntil input {
  margin-bottom: 15px;
}

/* Add custom styles for the specific elements if needed */

.insuranceCompany {
  margin-left: 24px;
}

/* Container for Extra Details */
.extraDetails {
  width: 1380px;
  border-radius: 17px;
  background: #fff;
  margin-top: 30px;
  margin-left: 27px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  align-items: start;
  height: 250px;
}

/* Style for the section header */
.extraDetailsHeader {
  grid-column: span 4;
  margin-bottom: 14px;
  height: 0;
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-top: 20px;
  margin-left: 19px;
}

/* Style for the input fields */
.extraDetailsDescription,
.extraDetailsBookingStatus,
.extraDetailsComplete,
.extraDetailsImages {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.extraDetailsDescription label,
.extraDetailsBookingStatus label,
.extraDetailsComplete label,
.extraDetailsImages label {
  display: none;
}

.extraDetailsDescription p,
.extraDetailsBookingStatus p,
.extraDetailsComplete p,
.extraDetailsImages p {
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}

.extraDetailsBookingStatus,
.bookingProgressPTag {
  margin-top: 10px;
  margin-bottom: 5px;
}

.form__input__booking[type="checkbox"] {
  margin-top: 8px;
  margin-left: 20px;
}

/* Style for checkbox label text */
.form__label {
  margin-top: 8px;
  margin-left: 20px;
}

.extraDetailsDescription {
  margin-left: 24px;
}

/* Style for the space div */
.space {
  margin-bottom: 15px; /* Adjust the margin as needed */
}

.newBookingFormSaveBtn {
  position: fixed;
  right: 20px; /* Adjust the right position as needed */
  top: 11%; /* Optional, you can adjust the vertical position */
}

/* Modal overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal content */
.modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  position: relative;
  text-align: center;
}

/* Modal header */
.modalHeader {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  position: relative; /* To position the close button independently */
  margin-bottom: 20px;
}

.modalHeader h2 {
  margin: 0;
  font-size: 1.5rem;
  text-align: center; /* Ensures heading text is centered */
  flex: 1; /* Makes sure it occupies available space to stay centered */
  font-family: inherit;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

/* Modal body */
.modalBody p {
  margin: 20px 0;
  font-size: 1rem;
  margin-bottom: 25px;
}
.modalActions {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  padding-bottom: 10px;
}

.requestBtn,
.payNowBtn {
  width: 150px; /* Set the same width for both buttons */
  height: 40px;
  padding: 10px 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center; /* Ensures text is centered within the button */
}

.requestBtn {
  background: #f0f0f0;
  color: #333;
}

.payNowBtn {
  background: #2dfd7a;
  color: white;
  /* color: #000; */
  background: #007bff;
}

@media (max-width: 1379px) {
  .newBookingForm {
    width: auto;
  }

  .newBookingForm .vehicleDetailsBookingForm {
    width: auto;
  }

  .newBookingForm .customerDetailsBookingForm {
    width: auto;
  }

  .newBookingForm .bookingDetails {
    width: auto;
  }

  .newBookingForm .additionalDriverBookingForm {
    width: auto;
  }

  .newBookingForm .paymentDetails {
    width: auto;
  }
}

@media (max-width: 768px) {
  /* Styles for landscape phones and smaller tablets */

  .newBookingForm {
    max-width: 700px;
  }

  .newBookingForm .vehicleDetailsBookingForm {
    max-width: 700px;
    width: auto;
    margin-left: 0px;
  }

  .newBookingForm .table-container {
    max-width: 700px;
    overflow-x: auto; /* Ensure table doesn't overflow */
  }

  .newBookingForm .table-container p {
    margin-bottom: 20px;
  }

  .newBookingForm .vehicleDetailsBookingForm .table-container table {
    display: block;
    width: 100%;
  }

  .newBookingForm .vehicleDetailsBookingForm .table-container thead {
    display: none;
  }

  .newBookingForm .vehicleDetailsBookingForm .table-container tbody {
    display: block;
  }

  .newBookingForm .vehicleDetailsBookingForm .table-container tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .newBookingForm .vehicleDetailsBookingForm .table-container td {
    display: inline-block;
    width: 48%; /* Two columns */
    box-sizing: border-box;
    padding: 5px 0;
  }

  .newBookingForm .bookingFormInputFields {
    max-width: 700px;
  }

  .newBookingForm .customerDetailsBookingForm {
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
    gap: 20px; /* Adjusts space between items */
    height: auto;
    margin-left: 0px;
  }

  .newBookingForm .customerDetailsBookingForm > div {
    width: 100%; /* Adjust width so divs fit within the grid */
    margin-bottom: 0; /* Remove the previous margin for grid compatibility */
    margin-right: 0; /* No need for right margin in grid */
  }

  .newBookingForm .additionalDriverBookingForm {
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two equal columns */
    gap: 20px; /* Adjusts space between items */
    height: auto;
    margin-left: 0px;
  }

  .newBookingForm .additionalDriverBookingForm > div {
    width: 100%; /* Adjust width so divs fit within the grid */
    margin-bottom: 0; /* Remove the previous margin for grid compatibility */
    margin-right: 0; /* No need for right margin in grid */
  }

  .newBookingForm .sectionHeader {
    align-self: stretch;
  }

  .newBookingForm .sectionHeaderAdditionalDriver {
    align-self: stretch;
    padding-bottom: 40px;
  }

  /* .paymentDetails {
    max-width: 700px;
    display: flex;
    flex-direction: row;
    height: auto;
    align-items: center;
    margin-left: 0px;
  }

  .paymentDetails > div {
    width: 700px;
    margin-bottom: 20px;
    margin-right: 45px;
    flex: 1;
  }

  .paymentDetailsHeader {
    flex-basis: 100%;
  } */

  .newBookingForm .paymentDetails {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns layout */
    grid-gap: 20px; /* Spacing between items */
    max-width: 700px;
    height: auto;
    margin-left: 0px;
  }

  .newBookingForm .paymentDetailsHeader {
    grid-column: span 2; /* Header spans both columns */
    margin-bottom: 20px;
  }

  .newBookingForm .paymentDetails > div {
    margin-bottom: 20px;
  }

  .newBookingForm .orderNumber {
    margin-left: 20px;
  }

  /* .bookingDetails {
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
    height: auto;
    margin-left: 0px;
  }

  .bookingDetails > div {
    width: 100%; 
    margin-left: 0;
  }

  .sectionHeaderBookingDetails {
    align-self: stretch;
  } */

  .newBookingForm .bookingDetails {
    grid-template-columns: 1fr 1fr; /* Switch to 1-column layout */
    max-width: 700px;
    gap: 20px;
    height: auto;
    margin-left: 0px;
    padding-bottom: 20px;
  }

  .newBookingForm .sectionHeaderBookingDetails {
    grid-column: span 2; /* Make the section header span both columns */
    margin-bottom: 40px;
  }

  .newBookingForm .pickUpAndDropOffLocation,
  .newBookingForm .chauffeurDetails,
  .newBookingForm .durationDetails,
  .newBookingForm .startEndDetails {
    grid-column: span 1; /* Each section takes full width */
    display: grid;
    grid-gap: 15px;
  }

  .newBookingForm .durationDetails,
  .newBookingForm .startEndDetails {
    grid-template-columns: auto; /* Keep internal 2-column layout within these sections */
  }

  .newBookingForm .durationDetails {
    margin-left: 20px;
  }

  .newBookingForm .durationDetails p {
    grid-column: span 2;
  }

  /* .pickUpAndDropOffLocation {
    margin-left: 0px;
    margin-top: 20px;
  }

  .chauffeurDetails {
    margin-left: 20px;
  }

  .startEndDetails {
    width: 700px;
  } */
}

@media (max-width: 480px) {
  .newBookingForm .vehicleDetailsBookingForm {
    max-width: 350px;
    margin-left: 0px;
  }

  .newBookingForm .vehicleDetailsBookingForm .table-container {
    max-width: 350px;
    overflow-x: auto; /* Ensure table doesn't overflow */
  }

  .newBookingForm .vehicleDetailsBookingForm .table-container p {
    margin-bottom: 20px;
  }

  .newBookingForm .vehicleDetailsBookingForm table {
    display: block;
    width: 100%;
  }

  .newBookingForm .vehicleDetailsBookingForm thead {
    display: none;
  }

  .newBookingForm .vehicleDetailsBookingForm tbody {
    display: block;
  }

  .newBookingForm .vehicleDetailsBookingForm tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .newBookingForm .vehicleDetailsBookingForm td {
    display: inline-block;
    width: 48%; /* Two columns */
    box-sizing: border-box;
    padding: 5px 0;
  }

  .newBookingForm .vehicleDetailsBookingForm td:nth-child(odd) {
    font-weight: bold; /* Optionally highlight label column */
  }

  .newBookingForm .bookingFormInputFields {
    max-width: 350px;
  }

  .newBookingForm .customerDetailsBookingForm {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    margin-left: 0px;
  }

  .newBookingForm .customerDetailsBookingForm > div {
    width: 150px;
    margin-bottom: 20px;
    margin-right: 45px;
  }

  .newBookingForm .additionalDriverBookingForm {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    margin-left: 0px;
  }

  .newBookingForm .additionalDriverBookingForm > div {
    width: 150px;
    margin-bottom: 20px;
    margin-right: 45px;
  }

  .newBookingFormv .sectionHeader {
    align-self: stretch;
  }

  .newBookingForm .sectionHeaderAdditionalDriver {
    align-self: stretch;
    padding-bottom: 40px;
  }

  .newBookingForm .paymentDetailsHeader {
    align-self: stretch;
  }

  .newBookingForm .paymentDetails {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    margin-left: 0px;
  }

  .newBookingForm .paymentDetails > div {
    width: 150px;
    margin-bottom: 20px;
    margin-right: 45px;
  }

  .newBookingForm .bookingDetails {
    max-width: 350px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .newBookingForm .bookingDetails > div {
    margin-bottom: 20px;
  }

  .newBookingForm .sectionHeaderBookingDetails {
    align-self: stretch;
  }

  .newBookingForm .pickUpAndDropOffLocation {
    margin-left: 0px;
    margin-top: 20px;
  }

  .newBookingForm .chauffeurDetails {
    margin-left: 20px;
  }

  .newBookingForm .startEndDetails {
    margin-left: 20px;
  }
}

/* Vehicle Details Page*/

.VehicleDetailsPage {
  width: 100%; /* Set the width to 100% to take up the full page width */
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 60px;
}

.vehicleDetailsPageHeader {
  height: 73px;
}
.vehicleDetailsPageHeader button {
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.44px;
  border: none;
  background-color: transparent;
  margin-left: 5px;
}

.vehicleDetailsPageTopHalf {
  display: flex;
  height: 500px;
}

.vehicleDetailsPageTopHalf {
  margin-bottom: -20px;
}

.vehicleDetailsPageBottomHalf {
  display: flex;
  height: 300px;
}

.imagesCollection,
.basicInformationCollection,
.bookingInformation,
.vehicleInformation {
  flex: 1;
}

.imagesCollectionNewVehicle {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
  width: 700px;
}

/* .imagesCollection img {
  height: 450px;
  width: 746px;
  margin-left: 24px;
} */

.imagesCollection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 450px;
  margin-left: 24px;
  object-fit: cover;
}

.imagesCollection img {
  object-fit: cover;
}

.slide {
  border-radius: 0.5rem;
  /* box-shadow: 0px 0px 7px #666; */
  width: 100%;
  height: 100%;
}

.slide-hidden {
  display: none;
}

.arrow {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 2rem;
  height: 2rem;
  color: white;
}

.arrow:hover {
  cursor: pointer;
}

.arrow-left {
  left: 1rem;
}

.arrow-right {
  right: 1rem;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator {
  background-color: white;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: grey;
}

.basicInformationCollection {
  display: flex;
  flex-direction: column;
}

.vehicleInfoAndDayRate,
.bookButton,
.dealershipInformationCollection {
  width: 100%; /* Span the width of the parent container */
  /* Add other styles as needed for each div */
}

.vehicleInfoAndDayRate {
  margin-left: 26px;
}

.vehicleInfoAndDayRate h3 {
  color: #000;
  font-family: Spartan;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.52px;
  margin-bottom: 25px;
}

.vehicleInfoAndDayRate p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
}

.vehicleInfoAndDayRate h2 {
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.68px;
  margin-top: 25px;
}

.vehicleInfoAndDayRate span {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.68px;
  width: 100px;
}

.bookButton button {
  width: 600px;
  height: 49px;
  flex-shrink: 0;
  margin-left: 37px;
  margin-top: 63px;
  border-radius: 5px;
  background: #dff0ff;
  border: none;
}

.bookButton :hover {
  background: #0561fc;
  color: white;
  cursor: pointer;
}

.bookButton p {
  color: #0561fc;
  text-align: center;
  font-family: Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.42px;
}

.bookButton:hover p {
  color: white;
}

.iconAndButton {
  max-width: 100px;
  height: 40px;
}

.iconAndButton .iconAndButtonButton {
  display: flex;
  align-items: center;
}

.iconAndButton .iconAndButtonButton {
  width: 70px;
}

.iconAndButton {
  margin-top: 27px;
  margin-left: 49px;
}

.iconAndButton :hover {
  background: #93a3ab;
  border-radius: 5px;
  cursor: pointer;
}

.dealershipInformationCollection {
  display: flex;
  justify-content: space-between;
  margin-top: 68px;
}

.dealershipInformationCollection p {
  color: #000;
  text-align: center;
  font-family: EB Garamond;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 37px;
}

.dealershipInformationCollection img {
  margin-right: 33px;
  max-width: 50px;
  max-height: 50px;
}

.bookingInformation h3 {
  color: #000;
  font-family: Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 24px;
}

.detailsBottomHalf {
  width: 675px;
  height: 280px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 24px;
  margin-top: 18px;
}

.justneedtomakeadiv {
  margin-left: 27px;
  padding-top: 20px;
}
.detailsBottomHalf p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
}

.vehicleInformation h3 {
  color: #000;
  font-family: Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 24px;
}

.vehicleDetailsBottomHalf {
  width: 675px;
  height: 280px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 24px;
  margin-top: 18px;
}

.vehicleDetailsBottomHalf p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
}

.needToMakeSpace {
  margin-left: 27px;
  padding-top: 20px;
}

/* Tablet and smaller devices */
@media (max-width: 1000px) {
  .VehicleDetailsPage .vehicleDetailsPageTopHalf,
  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    flex-direction: column; /* Stack items vertically */
  }

  .VehicleDetailsPage .vehicleDetailsPageTopHalf {
    height: 500px;
    width: 100%;

    height: auto;
  }

  .VehicleDetailsPage .imagesCollection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 450px;
    margin-left: 24px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    margin-top: 30px;
  }
}

@media (max-width: 730px) {
  .VehicleDetailsPage .vehicleDetailsPageTopHalf,
  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    flex-direction: column;
  }

  .VehicleDetailsPage .header {
    max-width: 700px;
  }

  .VehicleDetailsPage .vehicleDetailsPageTopHalf {
    height: 500px;
    width: 100%;

    height: auto;
  }

  .VehicleDetailsPage .imagesCollection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    height: 450px;
    margin-left: 24px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    margin-top: 30px;
  }
}

/* Mobile screens (below 495px) */
@media (max-width: 495px) {
  .VehicleDetailsPage .vehicleDetailsPageTopHalf,
  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    flex-direction: column;
  }

  .VehicleDetailsPage .vehicleDetailsPageTopHalf {
    height: 500px;
    width: 100%;

    height: auto;
  }

  .VehicleDetailsPage .imagesCollection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    height: 450px;
    margin-left: 24px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    margin-top: 30px;
  }

  .VehicleDetailsPage .vehicleDetailsPageBottomHalf .bookingInformation {
    max-width: 495px;
  }
}

@media (max-width: 431px) {
  .VehicleDetailsPage .vehicleDetailsPageTopHalf,
  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    flex-direction: column;
  }

  .VehicleDetailsPage .VehicleDetailsPage {
    margin-left: 10px;
  }

  .VehicleDetailsPage .vehicleDetailsPageTopHalf {
    height: 500px;
    width: 330px;

    height: auto;
  }

  .VehicleDetailsPage .bookButton button {
    width: 300px;
  }

  .VehicleDetailsPage .imagesCollection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    height: 250px;
    margin-left: 24px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    margin-top: 30px;
  }

  .VehicleDetailsPage .vehicleDetailsPageBottomHalf .justneedtomakeadiv {
    width: 300px;
  }

  .VehicleDetailsPage .detailsBottomHalf {
    max-width: 350px;
    margin-bottom: 15px;
  }

  .VehicleDetailsPage .vehicleDetailsBottomHalf {
    max-width: 350px;
  }
}

@media (max-width: 400px) {
  .VehicleDetailsPage .vehicleDetailsPageTopHalf,
  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    flex-direction: column;
  }

  .VehicleDetailsPage .vehicleDetailsPageTopHalf {
    height: 500px;
    width: 330px;

    height: auto;
  }

  .VehicleDetailsPage .bookButton button {
    width: 300px;
  }

  .VehicleDetailsPage .imagesCollection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    height: 250px;
    margin-left: 24px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .VehicleDetailsPage .vehicleDetailsPageBottomHalf {
    margin-top: 30px;
  }

  .VehicleDetailsPage .vehicleDetailsPageBottomHalf .justneedtomakeadiv {
    width: 300px;
  }

  .VehicleDetailsPage .detailsBottomHalf {
    max-width: 315px;
    margin-bottom: 15px;
  }

  .VehicleDetailsPage .vehicleDetailsBottomHalf {
    max-width: 315px;
  }
}

/* Dash Side Bar Customer*/

.dashSideBarCustomer {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 60px;
  bottom: 0%;
  background: #ffffff;
  width: 282px;
  height: 2500px;
  display: flex;
  flex-direction: column;
}

.dashSideBarCustomerAccountDivForSeperation {
  margin-bottom: 15px;
}

.dashSideBarRestOfLinks > div {
  margin-bottom: 15px;
}

.dashSideBarAccountCustomer {
  background: #e9f0f3;
  border-radius: 8px;
  width: 214px;
  height: 53px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-left: 34px;
  margin-top: 13px;
  position: relative;
  text-decoration: none;
}

.dashSideBarAccountCustomer p {
  margin-right: 5px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;

  color: #000000;

  mix-blend-mode: normal;

  text-decoration: none;
}

.home {
  margin-right: 15px;
}

.profileEdit {
  margin-right: 15px;
}

.orders {
  margin-right: 15px;
}

.manualBookingIcon {
  margin-right: 15px;
}

.reminders {
  margin-right: 15px;
}

.support {
  margin-right: 15px;
}

.dashSideBarHomeCustomer {
  width: 213.824px;
  height: 52.5px;
  flex-shrink: 0;
  margin-left: 34px;
  display: flex;
  align-items: center;
}

.dashSideBarProfileCustomer {
  width: 213.824px;
  height: 52.5px;
  flex-shrink: 0;
  margin-left: 34px;
  display: flex;
  align-items: center;
}

.dashSideBarOrdersCustomer {
  width: 213.824px;
  height: 52.5px;
  flex-shrink: 0;
  margin-left: 34px;
  display: flex;
  align-items: center;
}

.dashSideBarRemindersCustomer {
  width: 213.824px;
  height: 52.5px;
  flex-shrink: 0;
  margin-left: 34px;
  display: flex;
  align-items: center;
}

.dashSideBarMyCustomers {
  width: 213.824px;
  height: 52.5px;
  flex-shrink: 0;
  margin-left: 34px;
  display: flex;
  align-items: center;
}

.tooltipMyCustomers {
  transform: translateX(-470px) translateY(0px);
}

.dashSideBarMyVehicles {
  width: 213.824px;
  height: 52.5px;
  flex-shrink: 0;
  margin-left: 34px;
  display: flex;
  align-items: center;
}

.dashSideBarTransactions {
  width: 213.824px;
  height: 52.5px;
  flex-shrink: 0;
  margin-left: 34px;
  display: flex;
  align-items: center;
}

.dashSideBarSupportCustomer {
  width: 213.824px;
  height: 52.5px;
  flex-shrink: 0;
  margin-left: 34px;
  display: flex;
  align-items: center;
}

.inactive {
  text-decoration: none;
  color: #aeb9be;
  font-family: EB Garamond;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.15px;
  display: flex;
  align-items: center;
}

.active {
  text-decoration: none;
  border-radius: 8px;
  background: #edf4ff;
  width: 213.824px;
  height: 52.5px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.15px;
}

/* My Orders Customer */

.myOrdersCustomer {
  height: 100vh; /* 100% of the viewport height */
  width: calc(100vw - 282px);
  margin-left: 282px; /* Set to the width of the sidebar */

  margin-top: -40px;
}

.myOrdersSearchbar {
  height: 70px;
  width: calc(100vw - 282px);
  background: #fff;
}

.myOrdersH2 {
  height: 57px;
}

.myOrdersH2 h2 {
  color: #000;
  font-family: Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 26px;
  margin-left: 50px;
}

.myOrdersMain {
  width: 1046px;

  border-radius: 17px;
  background: #fff;
  margin-left: 40px;
}

.myOrdersData {
  margin-bottom: 66px;
}

.myOrdersFilterAndSearch {
  display: flex;
  justify-content: space-between;
}

.filterBtn {
  margin-top: 29px;
  margin-left: 47px;
  width: 73px;
  height: 25px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #dff0ff;
  cursor: pointer;
}

.filterBtn button {
  width: 73px;
  height: 25px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #dff0ff;
  outline: none;
  border: none;
  box-shadow: none;
  color: #0561fc;
  text-align: center;
  font-family: Spartan;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.27px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filterIconMyOrdersPageCustomer {
  margin-left: 7px;
  margin-right: 7px;
  color: #000;
}
/* General dropdown styling */
.filterDropdown {
  position: sticky;
  top: 100%;
  left: 0;
  background-color: white; /* White background */
  width: 100px;
  border: none; /* Remove default list border */
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  padding: 5px 0;
  z-index: 2; /* Ensure dropdown appears above other elements */
  display: none; /* Initially hidden */
}

.filterBtn .filterDropdown.show {
  display: block;
}

/* Remove list styles */
.filterDropdown li {
  list-style: none; /* Remove bullet points */
  margin: 0; /* Remove default list margins */
  padding: 5px 10px;
  cursor: pointer;
}

/* Font color and lines */
.filterDropdown li {
  color: #0561fc; /* Blue font color */
  border-bottom: 1px solid #ccc; /* Lines between items */
}

/* Selected item styling */
.filterDropdown li.selected {
  background-color: #e0f0ff; /* Light blue background for selected item */
  font-weight: bold; /* Bold font weight for selected item */
}

.searchBarOrdersPage {
  width: 200px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f8fafb;
  display: flex;
  align-items: center;
  color: #c1cace;
  font-family: EB Garamond;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 23px;
  margin-right: 24px;
}

.searchIconMyOrdersPageCustomer {
  margin-left: 15px;
  margin-right: 15px;
  color: #bcc4c8;
}

.myOrdersData {
  margin-top: 21px;
  margin-left: 47px;
  z-index: 2;
}

.myOrdersTable .orderNumberCell {
  width: 150px; /* Adjust as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.myOrdersMain .myOrdersData {
  padding-bottom: 60px;
}

.myOrdersSearchbarDiv {
  margin-left: 50px;
  margin-top: 11px;
  display: flex;
  align-items: center;
  width: 522.343px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f8fafb;
}

.myOrdersSearchbar {
  border: 2px solid #ffffff;
}
.myOrdersSearchbarIcon {
  margin-left: 22px;
  margin-right: 14px;
  color: #c1cace;
  font-family: EB Garamond;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.myOrdersSearchbarInputField input {
  border: none;
  background-color: transparent;
  outline: none;
  color: #c1cace;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 470px;
  height: 40px;
}

.myOrdersSearchbarInputField input::placeholder {
  font-family: EB Garamond;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #c1cace;
}

.searchBarOrdersPage input {
  border: none;
  background-color: transparent;
  outline: none;
  color: #c1cace;
  font-family: EB Garamond;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 150px;
}

.searchBarOrdersPage input::placeholder {
  color: #c1cace;
  font-family: EB Garamond;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.orderNumberCell > a {
  /* Styles for the link itself */
  color: #93a3ab; /* Example: Make the text blue */
  text-decoration: none; /* Example: Add an underline */
  cursor: pointer; /* Visually indicate clickability */
}

.orderNumberCell > a:hover {
  text-decoration: underline;
}

.dashboardCustomerOrdersLinks > a {
  color: #93a3ab; /* Example: Make the text blue */
  text-decoration: none; /* Example: Add an underline */
  cursor: pointer; /* Visually indicate clickability */
}

.dashboardCustomerOrdersLinks > a:hover {
  text-decoration: underline;
}

@media (max-width: 1370px) {
  .myOrdersCustomer .progressSystemExplainedCustomer {
    width: auto;
    margin-right: 40px;
    padding-bottom: 15px;
  }

  .myOrdersCustomer .myOrdersMain {
    width: auto;

    overflow-x: auto;
    margin-right: 40px;
  }

  .myOrdersCustomer .myOrdersData {
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling */
    margin-right: 40px;
  }

  .myOrdersCustomer .myOrdersTable {
    min-width: 800px; /* Set a reasonable minimum width for the table */
    width: 100%; /* Let it take up the available space */
    table-layout: auto; /* Ensure columns adjust to content */
  }
}

@media (max-width: 885px) {
  .myOrdersCustomer .progressSystemExplainedCustomer {
    width: auto;
    margin-right: 40px;
    padding-bottom: 15px;
  }

  .myOrdersCustomer .myOrdersSearchbar {
    width: 100%;
    display: none;
  }

  .myOrdersCustomer .myOrdersMain {
    width: auto;

    overflow-x: auto;
    margin-right: 40px;
  }

  .myOrdersCustomer .myOrdersSearchbarDiv {
    max-width: 350px;
  }

  .myOrdersCustomer .myOrdersSearchbarInputField {
    max-width: 100%;
  }

  .myOrdersCustomer .myOrdersSearchbarInputField input {
    max-width: 350px;
  }

  .myOrdersCustomer .myOrdersH2 {
    width: auto;
  }

  /* .myOrdersCustomer .myOrdersCustomerRemindersH3 {
    width: 100vw;
  } */

  .myOrdersCustomer .myOrdersData {
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling */
    margin-right: 40px;
  }

  .myOrdersCustomer .myOrdersTable {
    min-width: 300px; /* Set a reasonable minimum width for the table */
    width: 100%; /* Let it take up the available space */
    table-layout: auto; /* Ensure columns adjust to content */
  }

  .myOrdersCustomer {
    margin-left: 0px;
    width: 100%;
  }
}

@media (max-width: 430px) {
  .myOrdersCustomer .myOrdersSearchbar {
    width: 100%;
    max-width: 400px;
  }

  .myOrdersCustomer .myOrdersSearchbarDiv {
    width: 80%;
  }

  .myOrdersCustomer .myOrdersSearchbarInputField input {
    max-width: 250px;
  }

  .myOrdersCustomer .myOrdersFilterAndSearch .filterBtn {
    margin-right: 20px;
    margin-left: 20px;
  }

  .myOrdersCustomer .myOrdersH2 {
    max-width: 350px;
  }

  .myOrdersCustomer .myOrdersMain {
    max-width: 350px;
    overflow-x: auto;
  }

  .myOrdersCustomer .myOrdersData {
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling */
    /* margin-right: 40px; */
    margin-left: 20px;
  }

  .myOrdersCustomer .myOrdersTable {
    min-width: 80%; /* Set a reasonable minimum width for the table */
    width: 100%; /* Let it take up the available space */
    table-layout: auto; /* Ensure columns adjust to content */
  }
  .myOrdersCustomer .myOrdersTable th:nth-child(2),
  .myOrdersCustomer .myOrdersTable th:nth-child(3),
  .myOrdersCustomer .myOrdersTable th:nth-child(5),
  .myOrdersCustomer .myOrdersTable th:nth-child(6),
  .myOrdersCustomer .myOrdersTable th:nth-child(7),
  .myOrdersCustomer .myOrdersTable td:nth-child(2),
  .myOrdersCustomer .myOrdersTable td:nth-child(3),
  .myOrdersCustomer .myOrdersTable td:nth-child(5),
  .myOrdersCustomer .myOrdersTable td:nth-child(6),
  .myOrdersCustomer .myOrdersTable td:nth-child(7) {
    display: none; /* Hides columns Date, Item, Customer, Pick Up, Dropoff */
  }
}

/* Dashboard Customer Order Page*/

.dashboardCustomerOrderPageReview {
  width: 1380px;

  min-height: 700px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
}

.dashboardCustomerOrderPageH2AndBackButton {
  display: flex;
  justify-content: space-between;

  width: 90%;
}

.dashboardCustomerOrderPageH2AndBackButton button {
  display: flex;
  align-items: center;
  background-color: transparent;
  background-image: none;
  border: none;
  text-shadow: none;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  color: black;
  font-size: 16px;
  margin-top: -40px;
}

.dashboardCustomerOrderPageH2AndBackButton {
  padding-top: -40px;
}

.back-button-icon-customerDashboardOrderPage {
  margin-right: 5px;
}

.dashboardCustomerOrderPageFinancial {
  width: 100%;
  min-height: 200px;
  margin-left: 20px;
  border: 2px solid greenyellow;
}

.dashboardCustomerOrderPageCustomerDetails {
  /* width: 1380px; */
  width: 100%;
  min-height: 250px;
  margin-left: 40px;
  border: 2px solid greenyellow;
}

.dashboardCustomerOrderPagePaymentDetails {
  /* width: 1380px; */
  width: 100%;
  min-height: 75px;
  margin-left: 40px;
  border: 2px solid greenyellow;
}

.dashboardCustomerOrderPageDurationDetails {
  /* width: 1380px; */
  width: 100%;
  min-height: 100px;
  margin-left: 40px;
  border: 2px solid greenyellow;
}

.dashboardCustomerOrderPageExtraDetails {
  /* width: 1380px; */
  width: 100%;
  min-height: 75px;
  margin-left: 40px;
  border: 2px solid greenyellow;
}

.dashboardCustomerOrderPage h2 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.dashboardCustomerOrderPageFinancial {
  display: flex;
  align-items: flex-end;
  align-items: center;
}

.dashboardCustomerOrderPageFinancialTable {
  width: 1300px;
  margin-left: 50px;
}

.dashboardCustomerOrderPageFinancialTable td:nth-child(3),
.dashboardCustomerOrderPageFinancialTable th:nth-child(3) {
  width: 18%; /* Adjust width as desired */
}

.dashboardCustomerOrderPageFinancialImages img {
  width: 238px;
  height: 160px;
  flex-shrink: 0;
  border-radius: 7px 7px 0px 0px;
}

.dashboardCustomerOrderPageFinancialTable td:nth-child(6),
.dashboardCustomerOrderPageFinancialTable th:nth-child(6) {
  width: 15%; /* Adjust width as desired */
}

.dashboardCustomerOrderPageFinancialTable td:nth-child(1) {
  padding-right: 20px;
}

/* Test */

/* Test */

.dashboardCustomerOrderPageCustomerDetailsTopBit h4 {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
}

.dashboardCustomerOrderPageCustomerDetails {
  display: grid; /* Establish the parent div as a grid container */
  grid-template-rows: 50px 1fr; /* Define two rows: first with 50px height, second taking remaining space */
  grid-template-columns: 1fr 1fr 1fr; /* Create three equal-width columns */
}

.dashboardCustomerOrderPageCustomerDetailsTopBit {
  grid-row: 1; /* Place it in the first row */
  grid-column: 1 / 4; /* Span across all three columns */
  min-height: 50px;
}

.dashboardCustomerOrderPageCustomerDetailsPersonal,
.dashboardCustomerOrderPageCustomerDetailsDrivingLicenceDetails,
.dashboardCustomerOrderPageCustomerDetailsPassportDetails {
  grid-row: 2; /* Place them in the second row */
  min-height: 200px; /* Adjust height as needed */
}

.dashboardCustomerOrderPageCustomerDetailsTopBit p {
  color: #000;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 10px;
}

.dashboardCustomerOrderPageCustomerDetails p {
  color: #000;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.dashboardCustomerOrderPageCustomerDetailsPersonal p {
  margin-bottom: 10px; /* Add 10px bottom margin to each p element */
}

.dashboardCustomerOrderPageCustomerDetailsPersonal h5 {
  color: #0561fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageCustomerDetailsDrivingLicenceDetails p {
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageCustomerDetailsDrivingLicenceDetails h5 {
  color: #0561fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageCustomerDetailsPassportDetails p {
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageCustomerDetailsPassportDetails h5 {
  color: #0561fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.dashboardCustomerOrderPagePaymentDetails h4 {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
}

.dashboardCustomerOrderPagePaymentDetails p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 14px;
}

.dashboardCustomerOrderPageDurationDetails h4 {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageDurationDetails th {
  font-size: 16px;
}

.dashboardCustomerOrderPageDurationDetails td {
  color: black;
  font-size: 15.5px;
}

.dashboardCustomerOrderPageExtraDetails h4 {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageExtraDetails p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 14px;
}

.additionalDriverDetails {
  width: 1380px;
  min-height: 250px;
  margin-left: 40px;
  display: grid; /* Establish the parent div as a grid container */
  grid-template-rows: 50px 1fr; /* Define two rows: first with 50px height, second taking remaining space */
  grid-template-columns: 1fr 1fr 1fr; /* Create three equal-width columns */
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsTopBit {
  grid-row: 1; /* Place it in the first row */
  grid-column: 1 / 4; /* Span across all three columns */
  min-height: 50px;
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsPersonal,
.dashboardCustomerOrderPageAdditionalCustomerDetailsDrivingLicenceDetails,
.dashboardCustomerOrderPageAdditionalCustomerDetailsPassportDetails {
  grid-row: 2; /* Place them in the second row */
  min-height: 200px; /* Adjust height as needed */
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsTopBit h4 {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsTopBit p {
  color: #000;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 10px;
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsPersonal p {
  margin-bottom: 10px; /* Add 10px bottom margin to each p element */
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsPersonal h5 {
  color: #0561fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsDrivingLicenceDetails p {
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsDrivingLicenceDetails h5 {
  color: #0561fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsPassportDetails p {
  margin-bottom: 10px;
}

.dashboardCustomerOrderPageAdditionalCustomerDetailsPassportDetails h5 {
  color: #0561fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 15px;
  margin-bottom: 10px;
}

/* Customer dashoard Home Page*/
.dashboardCustomerHomePage {
  height: 100vh; /* 100% of the viewport height */
  width: calc(100vw - 282px);
  margin-left: 282px; /* Set to the width of the sidebar */

  margin-top: -40px;
}

.myHomePageH2 {
  height: 57px;
}

.myHomePageH2 h2 {
  color: #000;
  font-family: Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 26px;
  margin-left: 50px;
}

/* dashboard customer reminders */

.dashboardCustomerHomePageReminders {
  width: 1046px;
  min-height: 100px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 38px;
  margin-bottom: 30px;
  padding: 30px 0;
}

.dashboardCustomerHomePageReminders li {
  list-style-type: none;
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 40px;
}
/* Style the list of reminders */
.dashboardCustomerHomePageReminders ul {
  list-style: none; /* Remove default bullets */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

/* Style each reminder item */
.dashboardCustomerHomePageReminders li {
  padding-bottom: 15px; /* Add bottom padding for space between text and line */
  position: relative; /* Position for the line */
}

/* Add the line under each reminder */
.dashboardCustomerHomePageReminders li::after {
  content: "";
  position: absolute;
  bottom: 0; /* Align to bottom of the li */
  left: 0;
  right: 0;
  width: calc(100% - 40px);
  height: 1px; /* Adjust line thickness as needed */
  background-color: #ccc; /* Adjust line color as desired */
  bottom: 7px;
}

.dashboardCustomerHomePageRemindersH3 {
  color: #000;
  font-family: Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 60px;
  margin-bottom: 20px;
}

@media (max-width: 1370px) {
  .dashboardCustomerHomePage .dashboardCustomerHomePageReminders {
    width: auto;
    margin-right: 40px;
  }

  .dashboardCustomerHomePage .myOrdersMain {
    width: auto;

    overflow-x: auto;
    margin-right: 40px;
  }

  .dashboardCustomerHomePage .myOrdersData {
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling */
    margin-right: 40px;
  }

  .dashboardCustomerHomePage .myOrdersTable {
    min-width: 800px; /* Set a reasonable minimum width for the table */
    width: 100%; /* Let it take up the available space */
    table-layout: auto; /* Ensure columns adjust to content */
  }
}

@media (max-width: 885px) {
  .dashboardCustomerHomePage .dashboardCustomerHomePageReminders {
    width: auto;
    margin-right: 40px;
  }

  .dashboardCustomerHomePage .myOrdersSearchbar {
    width: 100%;
    display: none;
  }

  .dashboardCustomerHomePage .myOrdersMain {
    width: auto;

    overflow-x: auto;
    margin-right: 40px;
  }

  .dashboardCustomerHomePage .myOrdersSearchbarDiv {
    max-width: 350px;
  }

  .dashboardCustomerHomePage .myOrdersSearchbarInputField {
    max-width: 100%;
  }

  .dashboardCustomerHomePage .myOrdersSearchbarInputField input {
    max-width: 350px;
  }

  .dashboardCustomerHomePage .myHomePageH2 {
    width: auto;
  }

  .dashboardCustomerHomePage .dashboardCustomerHomePageRemindersH3 {
    width: 100vw;
  }

  .dashboardCustomerHomePage .myOrdersData {
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling */
    margin-right: 40px;
  }

  .dashboardCustomerHomePage .myOrdersTable {
    min-width: 300px; /* Set a reasonable minimum width for the table */
    width: 100%; /* Let it take up the available space */
    table-layout: auto; /* Ensure columns adjust to content */
  }

  .dashboardCustomerHomePage {
    margin-left: 0px;
    width: 100%;
  }
}

@media (max-width: 430px) {
  .dashboardCustomerHomePage .myOrdersSearchbar {
    width: 100%;
    max-width: 400px;
  }

  .dashboardCustomerHomePage .myOrdersSearchbarDiv {
    width: 80%;
  }

  .dashboardCustomerHomePage .myOrdersSearchbarInputField input {
    max-width: 250px;
  }

  .dashboardCustomerHomePage .myOrdersFilterAndSearch .filterBtn {
    margin-right: 20px;
    margin-left: 20px;
  }

  .dashboardCustomerHomePage .myHomePageH2 {
    max-width: 350px;
  }

  .dashboardCustomerHomePage .myOrdersMain {
    max-width: auto;
    overflow-x: auto;
  }

  .dashboardCustomerHomePage .myOrdersData {
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling */
    /* margin-right: 40px; */
    margin-left: 20px;
  }

  .dashboardCustomerHomePage .myOrdersTable {
    min-width: 80%; /* Set a reasonable minimum width for the table */
    width: 100%; /* Let it take up the available space */
    table-layout: auto; /* Ensure columns adjust to content */
  }
  .dashboardCustomerHomePage .myOrdersTable th:nth-child(2),
  .dashboardCustomerHomePage .myOrdersTable th:nth-child(3),
  .dashboardCustomerHomePage .myOrdersTable th:nth-child(5),
  .dashboardCustomerHomePage .myOrdersTable th:nth-child(6),
  .dashboardCustomerHomePage .myOrdersTable th:nth-child(7),
  .dashboardCustomerHomePage .myOrdersTable td:nth-child(2),
  .dashboardCustomerHomePage .myOrdersTable td:nth-child(3),
  .dashboardCustomerHomePage .myOrdersTable td:nth-child(5),
  .dashboardCustomerHomePage .myOrdersTable td:nth-child(6),
  .dashboardCustomerHomePage .myOrdersTable td:nth-child(7) {
    display: none; /* Hides columns Date, Item, Customer, Pick Up, Dropoff */
  }
}

/* dashboard customer the actual remiinders page */

.dashboardCustomerRemindersPage {
  margin-left: 225px;
  height: 100vh; /* 100% of the viewport height */
  width: calc(100vw - 282px);
  margin-left: 282px; /* Set to the width of the sidebar */
}

/* dashboard customer profile page */

.dashboardCustomerProfilePage {
  margin-left: 300px;
}

.dashboardCustomerProfilePage .dashboardCustomerProfilePageTitle {
  color: black;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 20px;
}

.dashboardCustomerProfilePageDetails {
  width: 1046px;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
}

.dashboardCustomerProfilePageDetailsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  grid-template-rows: repeat(3, 1fr); /* Three equal-height rows */
  gap: 20px; /* Adjust the gap between grid items as needed */
  padding-top: 60px;
  padding-left: 30px;
  width: 600px;
}

.dashboardCustomerProfilePageDetailsGrid .grid-item {
  /* Add styling for each grid item as needed */
  padding: 5px;
  width: 255px;
  height: 30px;
  background-color: #f8fafb;
  border-radius: 10px;
}

.grid-item {
  display: flex; /* Make grid items flex containers */
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically (optional) */
}

.dashboardCustomerProfilePageDetailsGrid {
  /* ... other styles ... */
  grid-column-gap: -100px; /* Adjust this value as needed */
  margin-top: -30px;
}

.dashboardCustomerProfilePageDetialsProfilePicture {
  width: 150px;
  height: 150px;
  float: right;
  margin-top: -140px;
  margin-right: 200px;
}

.dashboardCustomerProfilePageDetialsProfilePicture img {
  width: 250px;
  height: 150px;
}

/*.profile-picture-circle {
  border-radius: 50%; /* Make it a circle */

.dashboardCustomerProfileEditButton {
  width: 73px;
  height: 25px;
  color: #0561fc;
  background-color: #dff0ff;
  border: none;
  border-radius: 5px;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.27px;

  margin-top: 50px;
  margin-left: 30px;
}

.dashboardDealerManagePlanButton {
  width: 100px;
  height: 25px;
  color: #0561fc;
  background-color: #dff0ff;
  border: none;
  border-radius: 5px;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.27px;
  margin-top: 50px;
  margin-left: 30px;
}

.dashboardDealerManagePlanButton:hover {
  cursor: pointer;
}

.dashboardCustomerProfilePageMyDetailsPTag {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  padding-top: 20px;
  margin-left: 30px;
}

.stripeOnboardingContainer {
  width: 100%;
  min-height: 300px;
  padding-bottom: 20px;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
}

.stripeOnboardingContainer > div {
  width: 100%;
  height: auto;
}

.stripeOnboardingContainerHeader h3 {
  padding-top: 20px;
  margin-left: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.stripeOnboardingContainerLine {
  width: 100%;
  border: 1px solid #e5e7eb;
  margin-top: 10px;
}

.stripeOnboardingContainerBody .stripeOnboardingContainerBodyWarning {
  width: 100%;
  height: 60px;
  padding-left: 20px;
  padding-top: 10px;
}

.stripeOnboardingContainerBodyWarningSpan {
  background-color: #f8d7da;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: centre;
  justify-content: centre;
  display: inline-block;
  margin-right: 15px;
}

.stripeOnboardingContainerBodyWarningSpan p {
  margin-left: 17px;
  margin-top: 9px;
  color: red;
  font-weight: 500;
  font-size: 20px;
}

.stripeOnboardingContainerBodyWarningSpanComplete {
  background-color: #dcfce7;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: centre;
  justify-content: centre;
  display: inline-block;
  margin-right: 15px;
}

.stripeOnboardingContainerBodyWarningSpanComplete p {
  margin-left: 11px;
  margin-top: 6px;
  color: #22c55e;
  font-size: 22px;
}

.stripeOnboardingContainerBodyWarning span {
  font-weight: 500;
  font-size: 17px;
}

.stripeOnboardingContainerBodyText {
  padding-left: 20px;
  width: 90%;
  margin-top: 5px;
}

.stripeOnboardingContainerBodyConfirm {
  width: 56%;
  height: 50px;
  background-color: #f3f4f6;
  margin-top: 20px;
  color: #6b7280;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 10px;
}

.stripeIcon {
  font-size: 40px;
  margin-left: 5px;
}

.stripeOnboardingButton {
  width: auto;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  color: #0561fc;
  background-color: #dff0ff;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.27px;
  margin-left: 20px;
}

.stripeOnboardingButton:hover {
  cursor: pointer;
}

@media (max-width: 1370px) {
  .dashboardCustomerProfilePage .dashboardCustomerProfilePageDetailsGrid {
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: center;
  }

  .dashboardCustomerProfilePage .dashboardCustomerProfilePageDetails {
    width: 300px;
  }

  .dashboardCustomerProfilePage
    .dashboardCustomerProfilePageDetialsProfilePicture {
    float: none;
    margin-top: 20px;
    margin-left: 30px;
  }

  .dashboardCustomerProfilePage .dashboardCustomerProfileEditButton {
    margin-top: 20px;
    margin-left: 115px;
    margin-bottom: 10px;
  }
}

@media (max-width: 885px) {
  .dashboardCustomerProfilePage {
    margin-left: 0px;
  }
}

/* Dashboard Customer Support */

.dashboardCustomerSupport {
  display: grid;
  place-items: center;
}

/* Dashboard Dealership */

.dashboardDealership {
  height: 100vh; /* Full height of the viewport */
  margin-left: 300px;
  width: calc(100vw - 282px); /* Subtract sidebar width */
  margin-top: -40px;
}

.dashboardActiveVehicles:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dashboardDealershipAnalytics {
  width: 100%;
  min-height: 150px;
  display: flex; /* Make the parent div a flex container */
  flex-direction: row; /* Arrange items horizontally */
}

.dashboardDealershipAnalytics2 {
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
}

.dashbaordDealershipAnalyticsTotalRevenue,
.dashbaordDealershipAnalyticsTodayRevenue,
.dashbaordDealershipAnalyticsOrdersToday,
.dashbaordDealershipAnalyticsActiveVehicles {
  width: 250px;
  height: 125px;
  margin-right: 14px;
}

.dashbaordDealershipAnalyticsTotalRevenue {
  margin-left: 45px;
}

.dashboardDealershipAnalyticsWithExpressBtn {
  width: 93%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboardDealershipAnalyticsWithExpressBtn button {
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  border: none;
  background-color: #0561fc;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  cursor: pointer;
}

.dashboardDealership h2 {
  color: black;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;

  margin-top: 20px;
  margin-left: 45px;
  margin-bottom: 13px;
}

.dashboardDealershipSearchBar {
  height: 70px;
  width: 100%;
}

/* Shared styles for analytics containers */
.dashbaordDealershipAnalyticsTotalRevenue,
.dashbaordDealershipAnalyticsTodayRevenue,
.dashbaordDealershipAnalyticsOrdersToday,
.dashbaordDealershipAnalyticsActiveVehicles {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 17px;
  background: #fff;
  box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);
}

/* Shared styles for icon containers */
.dashbaordDealershipAnalyticsTotalRevenueIcon,
.dashbaordDealershipTodayRevenueIcon,
.dashbaordDealershipAnalyticsOrdersTodayIcon,
.dashbaordDealershipAnalyticsActiveVehiclesIcon {
  margin-left: 20px;
  width: 55px;
  height: 55px;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashbaordDealershipAnalyticsTotalRevenueIcon img {
  width: 55px;
  height: 55px;
}

.dashbaordDealershipTodayRevenueIcon img {
  width: 55px;
  height: 55px;
}

.dashbaordDealershipAnalyticsOrdersTodayIcon img {
  width: 55px;
  height: 55px;
}

.dashbaordDealershipAnalyticsActiveVehiclesIcon img {
  width: 55px;
  height: 55px;
}

.dashboardReportsDivIcon img {
  width: 55px;
  height: 55px;
}

/* Shared styles for details containers */
.dashbaordDealershipAnalyticsTotalRevenueDetails,
.dashbaordDealershipTodayRevenueDetails,
.dashbaordDealershipAnalyticsOrdersTodayDetails,
.dashbaordDealershipAnalyticsActiveVehiclesDetails {
  margin-left: 12px;

  padding: 15px; /* Add padding for spacing within details */
}

/* Shared typography styles for p tags only */
.dashbaordDealershipAnalyticsTotalRevenueDetails p,
.dashbaordDealershipTodayRevenueDetails p,
.dashbaordDealershipAnalyticsOrdersTodayDetails p,
.dashbaordDealershipAnalyticsActiveVehiclesDetails p {
  font-family: EB Garamond; /* Applied only to p tags */
  line-height: normal;
  color: #93a3ab;
  font-size: 19px;
  font-weight: 500;
}

/* Styles for h3 tags */
.dashbaordDealershipAnalyticsTotalRevenueDetails h3,
.dashbaordDealershipTodayRevenueDetails h3,
.dashbaordDealershipAnalyticsOrdersTodayDetails h3,
.dashbaordDealershipAnalyticsActiveVehiclesDetails h3 {
  color: #000;
  font-size: 26px;
  font-weight: 800;
  margin-top: 10px; /* Adjusted for better spacing */
}

/* line chart */

.dashboardDealershipAnalytics2LineChart {
  width: 780px;
  height: 400px;
  border-radius: 17px;
  background: #fff;
  box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);

  margin-left: 45px;
}

/* Style the X-axis labels */
.custom-x-axis-linechart .recharts-xaxis-tick-value {
  font-size: 16px;
  color: red;
  font-weight: bold;
}

/* Style the Y-axis labels */
.custom-y-axis-linechart .recharts-yaxis-tick-value {
  font-size: 14px;
  color: #999;
}

.dashboardDealershipAnalytics2LineChartTitle p {
  color: #000;
  font-family: spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin-left: 20px;
  padding-top: 28px;
}

.dashboardDealershipAnalytics2LineChartResponsiveContainer {
  margin-left: 25px;
  margin-top: 30px;
}

.dashboardDealershipAnalytics2BarChart {
  width: 254px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);
  margin-right: 40px;
}

.dashboardDealershipAnalytics2BarChartTitle p {
  color: #000;
  font-family: spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin-left: 20px;
  padding-top: 28px;
}

.dashboardDealershipAnalytics2BarChartResponsiveContainer {
  margin-top: 10px;
}

.dashboardDealershipRecentOrders {
  width: 1046px;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 45px;
  padding-bottom: 20px;
}

.dashboardDealershipRecentOrdersReportsPage {
  width: 1046px;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 15px;
  padding-bottom: 20px;
}

.tbcPage {
  margin-left: 275px;
}

.tbcPage h3 {
  color: #000;
  font-family: Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 10px;
}

.progressSystemExplained {
  width: 1046px;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 15px;
  margin-top: 30px;
}

.progressSystemExplained h3 {
  color: #000;
  font-family: Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 10px;
  padding-top: 20px;
  margin-left: 20px;
}

.progressSystemExplained p {
  margin-left: 20px;
  padding-right: 20px;
}

.progressSystemExplainedCustomer {
  width: 1046px;
  min-height: 200px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  margin-left: 40px;
  margin-top: 30px;
  padding-bottom: 15px;
}

.progressSystemExplainedCustomer h3 {
  color: #000;
  font-family: Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 10px;
  padding-top: 20px;
  margin-left: 20px;
}

.progressSystemExplainedCustomer p {
  margin-left: 20px;
  padding-right: 20px;
}

.toBeConfirmed {
  width: 1046px;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;

  padding-bottom: 20px;
}

.dashboardDealershipRecentOrdersTable {
  margin-left: 25px;
  margin-top: 20px;
}

/* Set the width for each column */
.dashboardDealershipRecentOrdersTable th:nth-child(1),
.dashboardDealershipRecentOrdersTable td:nth-child(1) {
  width: calc(14%); /* Adjust as needed for "Order" column */
}

.dashboardDealershipRecentOrdersTable th:nth-child(2),
.dashboardDealershipRecentOrdersTable td:nth-child(2) {
  width: calc(10%); /* Adjust as needed for "Date" column */
  padding-left: 10px;
}

.dashboardDealershipRecentOrdersTable th:nth-child(3),
.dashboardDealershipRecentOrdersTable td:nth-child(3) {
  width: calc(14%); /* Adjust as needed for "Item" column */
}

.dashboardDealershipRecentOrdersTable th:nth-child(4),
.dashboardDealershipRecentOrdersTable td:nth-child(4) {
  width: calc(14%); /* Adjust as needed for "Duration" column */
}

.dashboardDealershipRecentOrdersTable th:nth-child(5),
.dashboardDealershipRecentOrdersTable td:nth-child(5) {
  width: calc(10%); /* Adjust as needed for "Customer" column */
}

.dashboardDealershipRecentOrdersTable th:nth-child(6),
.dashboardDealershipRecentOrdersTable td:nth-child(6) {
  width: calc(14%); /* Adjust as needed for "Fulfilment Status" column */
  padding-left: 10px;
}

.dashboardDealershipRecentOrdersTable th:nth-child(7),
.dashboardDealershipRecentOrdersTable td:nth-child(7) {
  width: calc(14%); /* Adjust as needed for "Total" column */
  padding-left: 20px;
}

.dashboardOrdersBookingId:hover {
  text-decoration: underline;
  cursor: pointer;
}

.myOrdersDealershipFilterAndSearch {
  display: flex;
  justify-content: space-between;
}

.myOrdersSearchbarDealer {
  height: 70px;
  width: calc(100vw - 280px);
  background: #fff;
  border: 2px solid #ffffff;
  margin-left: -10px;
}

.myOrdersSearchbarDivDealer {
  margin-top: 11px;
  display: flex;
  align-items: center;
  width: 522.343px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f8fafb;
  margin-left: 40px;
}

/* dashboard my orders*/

.dashboardMyOrdersVehicleTimeLineh3:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Time line */

.vehicleTimeLine {
  margin-left: 280px;
}

.vehicleTimeLineToolTipCloseBtn {
  width: 73px;
  height: 25px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #dff0ff;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  color: #0561fc;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.27px;
}

.myVehiclesListDashboard {
  margin-left: 280px;
  width: 1100px;
  min-height: 300px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  padding-bottom: 20px;
}

.vehicleTimeLineH2 {
  color: #000;
  font-family: Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 280px;
  margin-top: 30px;
  margin-bottom: 15px;
}

/* Dealer Vehicle List */

.dealerVehicleTable table {
  margin-left: 20px;
  margin-top: 20px;
}

.dealerVehicleTable table td {
  text-decoration: none;
}

.dealerVehicleListMake:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* individual vehicle time line */

.individualVehicleTimeline {
  margin-left: 130px;
}

.individualVehicleTimelineToolTipCloseBtn {
  width: 73px;
  height: 25px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #dff0ff;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  color: #0561fc;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.27px;
}

.individualVehicleTimeline h1 {
  color: #000;
  font-family: Spartan;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 150px;
  margin-bottom: 20px;
}

/* Dashboard my customers */

.dashboardMyCustomersTable {
  width: 1046px;
  min-height: 250px;
  margin-left: 280px;
  border-radius: 17px;
  background: #fff;
  padding-bottom: 30px;
}

.dashboardMyCustomersLink {
  margin-left: 280px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.dashboardMyCustomersLink button {
  background: none;
  border: none;
}

.dashboardMyCustomersLink a {
  text-decoration: none;
  color: #000;
}

.dashboardMyCustomersLink a:hover {
  text-decoration: underline;
  color: #000;
}

.dashboardMyCustomersLink .copyIcon {
  color: #000;
  font-size: 24px;
  cursor: pointer;
  padding-left: 10px;
}
.copyPopup {
  position: fixed; /* Keeps the pop-up in a fixed position */
  top: 15px; /* Adjust the distance from the top edge of the screen */
  left: 50%; /* Centers it horizontally */
  transform: translateX(-50%); /* Ensures it is perfectly centered */
  background: #92fe9d; /* Dark background */
  color: black;

  padding: 5px 10px; /* Padding for visual comfort */
  border-radius: 5px; /* Rounded corners */
  font-size: 14px; /* Adjust as needed */
  opacity: 0.9; /* Slight transparency for aesthetics */
  z-index: 100; /* Ensures it stays above other content */
}

.dashboardMyCustomers h3 {
  margin-left: 280px;
  color: #000;
  font-family: Spartan;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 20px;
}

.dashboardMyCustomersTable table {
  margin-left: 50px;
}

/* my customers bookings list page individual */

.customerOrdersTable {
  width: 1046px;
  min-height: 250px;
  margin-left: 280px;
  border-radius: 17px;
  background: #fff;
  padding-bottom: 30px;
}

.customerOrdersTable footer {
  width: 1000px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f8fafb;
  margin-left: 25px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

.dashboardMyCustomersFooter2 {
  color: #0561fc;
  font-family: "EB Garamond";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.18px;
  display: flex;
}

.dashboardMyCustomersFooter2 span {
  color: #000;
  font-family: "EB Garamond";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.18px;
}

.dashboardMyCustomersFooter2 .infoIcon {
  border: solid;
  border-radius: 100%;
  font-size: 20px;
  margin-right: 10px;
  margin-top: 1px;
}

.dashboardMyCustomersFooter2 .infoIcon :hover {
  cursor: pointer;
}

.dashboardMyCustomersFooter2Info :hover {
  cursor: pointer;
}

.customerOrders h3 {
  margin-left: 280px;
  color: #000;
  font-family: Spartan;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 20px;
}

.customerOrdersTable table {
  margin-left: 50px;
  margin-top: 30px;
}

/* Set the width for each column */
.customerOrders th:nth-child(1),
.customerOrders td:nth-child(1) {
  width: calc(14%); /* Adjust as needed for "Order" column */
}

.customerOrders th:nth-child(2),
.customerOrders td:nth-child(2) {
  width: calc(8%); /* Adjust as needed for "Date" column */
  padding-left: 10px;
}

.customerOrders th:nth-child(3),
.customerOrders td:nth-child(3) {
  width: calc(14%); /* Adjust as needed for "Item" column */
}

.customerOrders th:nth-child(4),
.customerOrders td:nth-child(4) {
  width: calc(14%); /* Adjust as needed for "Duration" column */
  padding-left: 15px;
}

.customerOrders th:nth-child(5),
.customerOrders td:nth-child(5) {
  width: calc(14%); /* Adjust as needed for "Fulfilment Status" column */
  padding-left: 10px;
}

.customerOrders th:nth-child(6),
.customerOrders td:nth-child(6) {
  width: calc(14%); /* Adjust as needed for "Total" column */
  padding-left: 20px;
}

/* Add this to your CSS stylesheet */
.customerOrdersTable td.customerOrdersId a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.customerOrdersTable td.customerOrdersId a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Add this to your CSS stylesheet */
.dashboardMyCustomersTable td.customerOrdersLink a {
  text-decoration: none; /* Remove underline by default */
  color: inherit; /* Inherit color from parent */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.dashboardMyCustomersTable td.customerOrdersLink a:hover {
  text-decoration: underline; /* Underline on hover */
}

.dashboardMyCustomers .dashboardMyCustomersTable table {
  margin-top: 30px;
}

/* Dashboard My Vehicles */

.dashboardMyVehiclesTable {
  width: 1046px;
  min-height: 250px;
  margin-left: 280px;
  border-radius: 17px;
  background: #fff;
  padding-bottom: 30px;
}

.dashboardMyVehiclesTable table {
  margin-left: 50px;
}

/* Add this CSS to your stylesheet or component */
.dashboardMyVehiclesTable table th:nth-child(5),
.dashboardMyVehiclesTable table td:nth-child(5) {
  width: calc(9%); /* Adjust as needed for "Year" column */
}

.dashboardMyVehiclesTable table th:nth-child(6),
.dashboardMyVehiclesTable table td:nth-child(6) {
  width: calc(9%); /* Adjust as needed for "Colour" column */
}

.dashboardMyVehiclesTable table th:nth-child(7),
.dashboardMyVehiclesTable table td:nth-child(7) {
  width: calc(9%); /* Adjust as needed for "Mileage" column */
}

.dashboardMyVehiclesTable table th:nth-child(2),
.dashboardMyVehiclesTable table td:nth-child(2) {
  width: calc(10%); /* Adjust as needed for "Mileage" column */
}

.dashboardMyVehiclesTable table th:nth-child(1),
.dashboardMyVehiclesTable table td:nth-child(1) {
  width: calc(10%); /* Adjust as needed for "Mileage" column */
}

.dashboardMyVehiclesTable table th:nth-child(8),
.dashboardMyVehiclesTable table td:nth-child(8) {
  padding-left: 10px;
}

/* Add this to your CSS stylesheet */
.dashboardMyVehiclesTable td.dashboardMyVehiclesMakeLink a {
  text-decoration: none; /* Remove underline by default */
  color: inherit; /* Inherit color from parent */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.dashboardMyVehiclesTable td.dashboardMyVehiclesMakeLink a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* .dashboardMyVehicles .dashboardMyVehiclesTable table {
  margin-top: 30px;
} */

.dashboardMyVehicles .dashboardMyVehiclesTable table {
  margin-top: 30px;
}

.dashboardMyVehicles h3 {
  color: #000;
  font-family: Spartan;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 20px;
}

.dashboardMyvehiclesTitle {
  margin-left: 280px;
  display: flex;
  justify-content: space-between;
}

.dashboardMyvehiclesTitle button {
  color: #0561fc;
  font-family: "EB Garamond";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;

  width: 125px;
  height: 30px;
  flex-shrink: 0;

  border-radius: 5px;
  background: #dff0ff;
  border: none;
}

.dashboardMyvehiclesTitle button:hover {
  cursor: pointer;
}

/* My Vehicle Page */

.myVehiclePage {
  margin-left: 282px;
}

.myVehiclePageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myVehiclePageTitle h3 {
  color: #000;
  font-family: Spartan;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
}

.myVehiclePageTitle button {
  color: #0561fc;
  font-family: "EB Garamond";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;

  width: 100px;
  height: 30px;
  flex-shrink: 0;

  border-radius: 5px;
  background: #dff0ff;
  border: none;
}

.myVehiclePageTitle button:hover {
  cursor: pointer;
}

.myVehiclePageBody {
  width: 1046px;
  min-height: 600px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #fff;
  padding-bottom: 30px;
  margin-top: 20px;
}

.myVehiclePageBodyVehicleDetails {
  width: 100%;
  height: 225px;
  border-radius: 5px;
}

.myVehiclePageBodyVehicleNotesAndStatus {
  width: 100%;
  height: 300px;
  border-radius: 5px;
}

.myVehiclePageBodyVehicleSchedule {
  width: 100%;
  height: 100px;
  border-radius: 5px;
}

.myVehiclePageBodyVehicleDetails img {
  width: 356px;
  height: 199px;
}

.myVehiclePageBodyVehicleDetails {
  display: grid; /* Make the parent div a grid container */
  grid-template-columns: 1fr 2fr; /* Create two equal columns */
}

.myVehiclePagebodyVehicleDetailsInfo {
  margin-left: 16px;
}

.myVehiclePagebodyVehicleDetailsInfoTitleBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 12px;
}

.myVehiclePagebodyVehicleDetailsInfo
  .myVehiclePagebodyVehicleDetailsInfoTitle
  h2 {
  color: #000;
  font-family: Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
}

.myVehiclePagebodyVehicleDetailsInfoTitleBody p {
  color: #93a3ab;
  font-family: "EB Garamond";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 10px;
}

.myVehiclePageBodyVehicleNotesAndStatus {
  display: flex;
}

.myVehiclePageBodyVehicleNotes,
.myVehiclePageBodyVehicleStatus {
  flex: 1;
  /* You can add additional styling as needed */
}

.myVehiclePageBodyVehicleNotes h4 {
  color: #93a3ab;
  font-family: "EB Garamond";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 10px;
}

.myVehiclesPageBodyVehicleNotesDiv {
  width: 600px;
  height: 250px;

  border-radius: 8px;
  background: #f8fafb;
}

.myVehiclePageBodyVehicleStatus h4 {
  color: #93a3ab;
  font-family: "EB Garamond";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 50px;
  margin-bottom: 10px;
}

.myVehiclesPageBodyVehicleStatusDiv {
  width: 300px;
  height: 250px;
  border-radius: 8px;
  background: #f8fafb;
  margin-left: 50px;
}

.myVehiclesPageBodyVehicleNotesDiv p {
  padding-top: 15px;
  margin-left: 15px;
}

.myVehiclesPageBodyVehicleStatusDiv p {
  padding-top: 15px;
  margin-left: 15px;
}

.myVehiclePageBodyVehicleSchedule h4 {
  color: #93a3ab;
  font-family: "EB Garamond";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 20px;
}

.myVehiclePageBodyVehicleSchedule p {
  margin-top: 10px;
  margin-left: 20px;
}

.myVehiclePageBodyVehicleNotes {
  margin-left: 20px;
}

.myVehiclePageBodyVehicleDetails {
  margin-left: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
}

/* Dashboard Reports */

.dashboardReports {
  margin-left: 282px;
}

.dashboardReports h1 {
  color: #000;
  font-family: Spartan;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 20px;
}

.dashboardReportsPages {
  display: flex;
}

.dashboardReportsPages .dashboardReportsDiv {
  width: 250px;
  height: 125px;

  margin-left: 15px;
  border-radius: 17px;
  background-color: #ffffff;
}

.dashboardReportsPages {
  width: 100%;
  min-height: 150px;
  display: flex; /* Make the parent div a flex container */
  flex-direction: row; /* Arrange items horizontally */
}

/* Shared styles for analytics containers */
.dashboardReportsDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 17px;
  background: #fff;
  box-shadow: 0px 21px 94px 0px rgba(0, 0, 0, 0.03);
}

/* Shared styles for icon containers */
.dashboardReportsDivIcon {
  margin-left: 20px;
  width: 55px;
  height: 55px;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Shared styles for details containers */
.dashboardReportsDivDetails {
  margin-left: 12px;

  padding: 15px; /* Add padding for spacing within details */
}

/* Shared typography styles for p tags only */
.dashboardReportsDivDetails p {
  font-family: EB Garamond; /* Applied only to p tags */
  line-height: normal;
  color: #93a3ab;
  font-size: 19px;
  font-weight: 500;
}

/* Styles for h3 tags */
.dashboardReportsDivDetails h3 {
  color: #000;
  font-size: 26px;
  font-weight: 800;
  margin-top: 10px; /* Adjusted for better spacing */
}

.dashboardReportDivDetailsTimeline:hover,
.dashboardReportDueForDelivery:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Due for delivery */

.DueforDelivery {
  margin-left: 282px;
}

.dueForDeliveryTable {
  width: 1050px;
  min-height: 200px;
  background: #ffffff;
  border-radius: 17px;
  padding-bottom: 30px;
}

.dueForDeliveryTable table {
  margin-left: 40px;
  margin-top: 30px;
}

/* Set the width for each column */
.dueForDeliveryTable th:nth-child(1),
.dueForDeliveryTable td:nth-child(1) {
  width: calc(14%); /* Adjust as needed for "Order" column */
}

.dueForDeliveryTable th:nth-child(2),
.dueForDeliveryTable td:nth-child(2) {
  width: calc(10%); /* Adjust as needed for "Date" column */
  padding-left: 10px;
}

.dueForDeliveryTable th:nth-child(3),
.dueForDeliveryTable td:nth-child(3) {
  width: calc(14%); /* Adjust as needed for "Item" column */
}

.dueForDeliveryTable th:nth-child(4),
.dueForDeliveryTable td:nth-child(4) {
  width: calc(14%); /* Adjust as needed for "Duration" column */
}

.dueForDeliveryTable th:nth-child(5),
.dueForDeliveryTable td:nth-child(5) {
  width: calc(10%); /* Adjust as needed for "Customer" column */
}

.dueForDeliveryTable th:nth-child(6),
.dueForDeliveryTable td:nth-child(6) {
  width: calc(14%); /* Adjust as needed for "Fulfilment Status" column */
  padding-left: 10px;
}

.dueForDeliveryTable th:nth-child(7),
.dueForDeliveryTable td:nth-child(7) {
  width: calc(14%); /* Adjust as needed for "Total" column */
  padding-left: 20px;
}
.dueForDeliveryTableId:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* damaged vehicles */

.damagedVehicles {
  margin-left: 282px;
}

.damagedVehiclesTable {
  width: 1050px;
  min-height: 200px;
  background: #ffffff;
  border-radius: 17px;
  padding-bottom: 30px;
}

.damagedVehiclesTable table {
  margin-left: 30px;
  margin-top: 30px;
}

/* dashboard support */

.dashboardSupport {
  display: grid;
  place-items: center;
}

.dashboardSupport span:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Home Side Bar*/

.homeSideBar {
  position: absolute;
  width: 250px;
  height: 775px;
  left: 24px;
  top: 100px;
  padding-bottom: 20px;

  background: #ffffff;
  border-radius: 17px;
}

.homeSideBarInfo {
  width: 100%;
  height: 130px;

  border-bottom: 2px solid #e4ebef;
}

.homeSideBarInfo h1 {
  /* 33 Cars for Hire */

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  /* identical to box height */
  text-align: center;

  color: #000000;
  margin-top: 30px;
}

.homeSideBarInfo h3 {
  /* 3 filters selected */

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;

  color: #0561fc;

  margin-top: 20px;
}

.homeSideBarDistance {
  border-bottom: 2px solid #e4ebef;
  padding-bottom: 30px;
  padding-top: 30px;
}

.homeSideBarDistance .homeSideBarDistanceInput {
  /* Rectangle 35 */

  width: 225px;
  height: 30px;

  background: #f8fafb;
  border-radius: 10px;
  margin-left: 15px;

  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.homeSideBarDistanceInput p {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
}

.homeSideBarVehicle {
  padding-top: 30px;
  border-bottom: 2px solid #e4ebef;
  padding-bottom: 30px;
}

.homeSideBarVehicle .homeSideBarVehicleInput {
  width: 225px;
  height: 30px;

  background: #f8fafb;
  border-radius: 10px;
  margin-left: 15px;

  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homeSideBarVehicleInput p {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;

  margin-left: 15px;
}

.homeSideBarVehicleInput .homeSideBarVehicleInputIcon {
  color: #0561fc;
  margin-right: 10px;
  margin-left: 10px;
}

.homeSideBarVehicleInputData span {
  /* Lamborghini */

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.02em;

  color: #000000;
}

.homeSideBarVehicleInputData {
  display: flex;
  align-items: center;
}

.homeSideBarPrice {
  padding-top: 30px;
  border-bottom: 2px solid #e4ebef;
  padding-bottom: 30px;
}

.homeSideBarPrice .homeSideBarPriceInput {
  width: 225px;
  height: 30px;

  background: #f8fafb;
  border-radius: 10px;
  margin-left: 15px;

  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.homeSideBarPriceInput p {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;

  margin-left: 15px;
}

.homeSideBarPriceInput .homeSideBarPriceInputIcon {
  color: #0561fc;
  margin-right: 10px;
  margin-left: 10px;
}

.homeSideBarTimesInput .homeSideBarPriceInputIcon {
  color: #0561fc;
  margin-right: 10px;
  margin-left: 10px;
}

.homeSideBarPriceInputData span {
  /* Lamborghini */

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.02em;

  color: #000000;
}

.homeSideBarPriceInputData {
  display: flex;
  align-items: center;
}

.homeSideBarDates {
  /* border-bottom: 2px solid #e4ebef; */
  padding-bottom: 30px;
  padding-top: 30px;
}

/* .homeSideBarTimes {
  padding-bottom: 30px;
  padding-top: 30px;
} */

.homeSideBarDates .homeSideBarDatesInput {
  /* Rectangle 35 */

  width: 225px;
  height: 30px;

  background: #f8fafb;
  border-radius: 10px;
  margin-left: 15px;

  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.homeSideBarTimes .homeSideBarTimesInput {
  width: 225px;
  height: 30px;

  background: #f8fafb;
  border-radius: 10px;
  margin-left: 15px;

  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.homeSideBarTimes .homeSideBarTimesInput .form__label__filter {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
}

.homeSideBarDatesInput p {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
}

/* Style the input fields */
.form__input__filter {
  width: 150px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
  padding-left: 20px;
}

/* Show the placeholder text initially */
.form__input__filter::placeholder {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: center; /* Center placeholder text horizontally */
}

.homeSideBarDatesInput .form__label__filter {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
}

.form__input__filter--make {
  width: 190px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
  padding-left: 15px;
  outline: none;
}

.form__input__filter--make::placeholder {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.form__input__filter--distance {
  width: 150px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
  text-align: center;

  outline: none;
}

.form__input__filter--distance::placeholder {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: center;
}

.form__input__filter--distanceOptions {
  width: 83px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
  text-align: center;

  outline: none;
}

.form__input__filter--price {
  width: 180px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
  /* text-align: center; */

  /* outline: none; */
  padding-left: 15px;
}

.form__input__filter--startTime {
  width: 120px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
  /* text-align: center; */

  outline: none;
  padding-left: 15px;
}

.form__input__filter--endTime {
  width: 120px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #f8fafb;
  font-size: 16px;
  padding: 5px; /* Add padding to center text vertically */
  border: none;

  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: -0.02em;

  color: #93a3ab;
  /* text-align: center; */

  outline: none;
  padding-left: 15px;
}

.form__input__filter--price::placeholder {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: center;
}

.homeSideBar {
  display: block; /* Default display */
  transition: transform 0.3s ease; /* Optional: add a smooth transition */
  z-index: 1;
}

.hamburger-icon {
  display: none;
  z-index: 3;
}

.hamburger-icon-text {
  display: none;
}

.iphoneMainPage button {
  display: none;
}

@media (max-width: 550px) {
  .homeSideBar {
    display: none; /* Hide sidebar by default */
    margin-top: 40px;
  }

  .homeSideBar.visible {
    display: block; /* Show sidebar when the class 'visible' is applied */
    /* Optionally, you could add additional styles here to position it */
    z-index: 1;
  }

  .vehicleCard {
    margin-left: -330px;
  }

  .iphoneMainPage button {
    display: inline-block;
  }

  .hamburger-icon {
    display: block; /* Show the hamburger icon */
    cursor: pointer;
    z-index: 3;
    color: #adadaf;
    margin-right: 10px;
  }

  .hamburger-icon-text {
    display: block;
    color: #adadaf;
  }

  .iphoneMainPage {
    display: flex;
    align-items: center; /* Optional: vertically aligns the children if needed */
    justify-content: space-between;
  }

  /* .homeSideBarHam {
    width: 320px;
    height: 30px;
    background-color: #434344;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  } */

  .homeSideBarHam {
    width: 160px;
    height: 35px;
    background-color: #434344;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 20px; */
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .iphoneMainPage button {
    width: 160px;
    height: 35px;
    margin-bottom: 20px;
    color: #007bff;
    background-color: #dff0ff;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
  }
}

.mobile-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f44336; /* Red background */
  color: white;
  padding: 10px;
  font-size: 18px;
  text-align: center;
  position: fixed;
  top: 4%;
  left: 0;
  width: 100vw;
  z-index: 999;
}

/* Account */

.account {
  width: 100%;
  height: 350px;
  border: 2px solid black;
  display: flex;
  justify-content: space-between;
}

.customer {
  width: 50%;
  height: 350px;
  border: 2px solid black;
}

.dealer {
  width: 50%;
  height: 350px;
  border: 2px solid black;
}

/* Dashboard Active Vehicles Page */

.dashboardActiveVehiclesPage {
  height: 100%; /* Full height of the viewport */
  margin-left: 300px;
  width: calc(100vw - 282px); /* Subtract sidebar width */
  margin-top: -40px;
  text-decoration: none;
}

.dashboardActiveVehiclesPage h2 {
  color: black;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;

  margin-top: 20px;
  margin-left: 45px;
  margin-bottom: 13px;
}

/* dashboard orders today page */

.dashboardOrdersTodayPage {
  height: 100%; /* Full height of the viewport */
  margin-left: 300px;
  width: calc(100vw - 282px); /* Subtract sidebar width */
  margin-top: -40px;
  text-decoration: none;
}

.dashboardOrdersTodayPage h2 {
  color: black;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;

  margin-top: 20px;
  margin-left: 45px;
  margin-bottom: 13px;
}

/* Pagination*/

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  cursor: pointer;
  width: 35px;
  height: 40px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-weight: 550;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border: none;
  width: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination button:hover {
  background-color: #0056b3;
  color: white;
}

/* Dash side bar customer media query*/

@media (max-width: 885px) {
  .dashSideBarCustomer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 70px;
    background-color: #fff; /* Adjust color as needed */
    display: flex;
    justify-content: center;
    top: unset;
    right: unset;
    /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); */
    /* z-index: 1000; */
  }

  /* .dashSideBarCustomer .myOrdersSearchbarDiv {
    width: 100%;
  } */

  .dashSideBarCustomer .dashSideBarCustomerAccountDivForSeperation {
    display: none; /* Hides the account section */
  }

  .dashSideBarCustomer .dashSideBarRestOfLinks {
    display: flex; /* Aligns the children in a row */
    justify-content: space-around;
    width: auto;
    margin-top: 20px;
  }

  .dashSideBarCustomer .dashSideBarRestOfLinks div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100px;
    width: auto;
    margin-left: 0px;
    margin-right: 0px;
  }

  .dashSideBarCustomer .dashSideBarRestOfLinks div a {
    display: flex;
    flex-direction: column;
  }

  .dashSideBarCustomer .inactive {
    text-decoration: none;
    color: #aeb9be;
    font-family: EB Garamond;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.15px;
    display: flex;
    align-items: center;
  }

  .dashSideBarCustomer .active {
    text-decoration: none;
    border-radius: 8px;
    background: none;
    width: auto;
    /*  height: 30px; */
    flex-shrink: 0;
    display: flex;
    align-items: center;
    color: #0561fc;
    font-family: EB Garamond;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.15px;
  }
}

@media (max-width: 706px) {
  .dashSideBarCustomer .home {
    margin-right: 0px;
  }

  .dashSideBarCustomer .profileEdit {
    margin-right: 0px;
  }

  .dashSideBarCustomer .orders {
    margin-right: 0px;
  }

  .dashSideBarCustomer .manualBookingIcon {
    margin-right: 0px;
  }

  .dashSideBarCustomer .reminders {
    margin-right: 0px;
  }

  .dashSideBarCustomer .support {
    margin-right: 0px;
  }

  .dashSideBarCustomer .dashSideBarRestOfLinks div a {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
}

@media (max-width: 430px) {
  /* Show only Order, Duration, and Booking Progress */
  .myOrdersCustomer .myOrdersTable th:nth-child(1),
  .myOrdersCustomer .myOrdersTable th:nth-child(3),
  .myOrdersCustomer .myOrdersTable th:nth-child(4),
  .myOrdersCustomer .myOrdersTable th:nth-child(6),
  .myOrdersCustomer .myOrdersTable td:nth-child(1),
  .myOrdersCustomer .myOrdersTable td:nth-child(3),
  .myOrdersCustomer .myOrdersTable td:nth-child(4),
  .myOrdersCustomer .myOrdersTable td:nth-child(6) {
    display: table-cell; /* Show the required columns */
  }

  /* Hide all other columns */
  .myOrdersCustomer .myOrdersTable th,
  .myOrdersCustomer .myOrdersTable td {
    display: none; /* Hide all by default */
  }
}

@media (max-width: 1370px) {
  .dashboardCustomerRemindersPage .dashboardCustomerHomePageReminders {
    width: auto;
    margin-right: 40px;
  }
}

@media (max-width: 885px) {
  .dashboardCustomerRemindersPage {
    margin-left: 0px;
    width: 100vw;
  }
}

/* Pricing Page */

.pricingDealer {
  height: 100vh; /* 100% of the viewport height */
  width: calc(100vw - 282px);
  margin-left: 282px; /* Set to the width of the sidebar */
}

.pricingDealer h3 {
  color: #000;
  font-family: Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-left: 24px;
}
.pricingDealer .pricingCardContainer {
  width: 90%;
  height: 580px;
  margin-left: 40px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.pricingCard {
  height: 100%;
  width: 315px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 5%;

  position: relative; /* Make it a positioning context for the banner */
  overflow: hidden;
}

@keyframes lightningBorderPricingCard {
  0% {
    border: 2px solid rgba(5, 97, 252, 0); /* Transparent border */
    box-shadow: 0 0 10px rgba(5, 97, 252, 0.5);
  }
  50% {
    border: 2px solid rgba(5, 97, 252, 1); /* Fully visible blue border */
    box-shadow: 0 0 30px rgba(5, 97, 252, 1);
  }
  100% {
    border: 2px solid rgba(5, 97, 252, 1); /* Retain the fully visible blue border */
    box-shadow: 0 0 15px rgba(5, 97, 252, 1);
  }
}

.pricingCard {
  border: 2px solid transparent; /* Initial border state */
  transition: border 0.3s ease-in-out; /* Smooth transition */
}

.otherPlan:hover {
  border-color: #0561fc; /* Ensure the border stays blue after the animation */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.enterprisePlan:hover {
  animation: lightningBorderPricingCard 0.6s ease-in-out forwards;
  border-color: #0561fc; /* Ensure the border stays blue after the animation */
}

.customToolTipPricingCard {
  background-color: black !important; /* Set to black */
  color: white !important;
}

.custom-tooltip {
  max-width: 400px; /* Adjust as needed */
  word-wrap: break-word;

  background-color: rgb(0, 0, 0) !important; /* Solid black */
  color: #ffffff !important; /* White text */
  opacity: 1 !important; /* Ensure no opacity */
  box-shadow: none !important; /* If needed, remove any shadow that could affect the appearance */
  /* z-index: 2000; */
}

.pricingCardTopHalf {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricingCardTopHalf h4 {
  color: black;
  font-family: "Times New Roman", Times, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.pricingCardTopHalf p {
  margin-bottom: 13px;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #e7e7e7;
  padding-bottom: 13px;
  font-size: 15px;
}

.pricingCardTopHalf span {
  margin-bottom: 13px;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #e7e7e7;
  padding-bottom: 13px;
}

.pricingCardInfoIcon {
  margin-left: 5px;
  font-size: 18px;
  transform: translateY(4px);
  /* cursor: pointer; */
}

.pricingCardInfoIconDealerInfoBox {
  margin-left: 5px;

  transform: translateY(3.5px);
  color: #000;
}

.pricingCardTick {
  color: rgb(1, 208, 1);
  margin-right: 10px;
}

.pricingCardTickMiddlePlan {
  color: black;
  margin-right: 10px;
}

.pricingCardBottomHalf {
  width: 100%;

  display: flex;
  justify-content: center;
}

.pricingCardBottomHalf button {
  background-color: #dff0ff;
  border: none;
  border-radius: 5px;
  color: #0561fc;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 20px;
  width: 80%;
}

.pricingDealerInfoBox {
  width: 90%;
  background-color: white;
  border-radius: 10px;
  min-height: 300px;
  margin-left: 40px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.pricingDealerInfoBox h2 {
  color: #0561fc;
  font-family: EB Garamond;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.36px;
  line-height: normal;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pricingDealerInfoBox p {
  margin-left: 10px;
  padding-bottom: 10px;
}

.pricingDealerInfoBoxHeading {
  font-weight: bold;
  text-decoration: underline;
}

.enterprisePlanStrikeThrough {
  text-decoration: line-through;
  font-style: italic;
  color: #93a3ab;
  margin-right: 5px;
}

.enterprisePlanStrikeThroughNone {
  text-decoration: none;
  font-style: normal;
  color: #000;
}

.bannerEnterprisePlan {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff6347; /* Red banner color */
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  text-align: center;
  z-index: 1;
  width: 100%;
}

.pricingDealerHeader {
  width: 93%;
  display: flex;
  justify-content: space-between;
}

/* Footer */

.Footer {
  height: 50px; /* Set the height of the footer */
  width: 100vw; /* Full width of the viewport */
  background-color: black; /* Black background */
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}

.Footer p {
  color: white; /* White text color */
  margin: 0 5px; /* 10px gap between paragraphs (5px margin on each side) */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.Footer p:hover {
  cursor: pointer; /* Change cursor to pointer on hover */
  text-decoration: underline; /* Underline text on hover */
}

/* Terms and Conditions */

.termsAndConditions {
  height: 100vh; /* 100% of the viewport height */
  width: 100vw;
}

.termsAndConditions h3 {
  margin-left: 40px;
  color: black;
  font-family: "Times New Roman", Times, serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-bottom: 20px;
}

.termsAndConditions p {
  margin-left: 40px;
  margin-right: 20px;
}

.termsAndConditions h2 {
  margin-left: 40px;
  color: black;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 20px;
}

.termsAndConditions li {
  margin-left: 40px;
}

/* Dealership Landing Page*/

.dealershipInfo {
  /* Set the height to fill the viewport if necessary */
  height: auto;

  /* Apply the gradient background */
  /* background: linear-gradient(to top, #0561fc, rgb(0, 0, 42)); */
  background: linear-gradient(to top, #0561fc 35%, rgb(0, 0, 42) 85%);
  width: 100vw;
  margin-top: -40px;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dealershipLandingPageTopHalf {
  width: 50%;
  min-height: 355px;
  height: auto;
  /* border: 2px solid white; */
  text-align: center;
}

.dealershipLandingPageMiddle {
  margin-top: 30px;
}

.dealershipLandingPageMiddle h1 {
  font-size: 2.5rem; /* Large font size */
  font-weight: bold;
  color: #403f3f; /* Fallback color in case gradient doesn't render */
  background: linear-gradient(to bottom, #f0f0f0, #a3a3a3);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-align: center; /* Center the text */
  line-height: 1.2; /* Adjust line height for a clean look */
}

.dealershipLandingPageBottomHalf {
  width: 70%;
  height: 550px;
  border: 1px solid rgba(255, 255, 255, 0.5); /* Thin white, slightly transparent border */
  border-radius: 15px; /* Rounded edges */
  background: rgba(
    255,
    255,
    255,
    0.1
  ); /* Semi-transparent background for foggy effect */
  backdrop-filter: blur(8px); /* Adds a blur effect to the background */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  color: #fff; /* White text for contrast */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  margin-top: 10px;
}

.dealershipLandingPageTopHalf {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 60px;
}

.dealershipLandingPageTopHalf h1 {
  font-size: 4rem; /* Large font size */
  font-weight: bold;
  color: #403f3f; /* Fallback color in case gradient doesn't render */
  background: linear-gradient(to bottom, #f0f0f0, #a3a3a3);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-align: center; /* Center the text */
  line-height: 1.2; /* Adjust line height for a clean look */
}

.dealershipLandingPageTopHalf p {
  color: #a3a3a3;
  margin-top: 10px;
}

.dealershipLandingPageTopHalf li {
  color: #a3a3a3;
  margin-top: 10px;
}

@keyframes lightningBorder {
  0% {
    border: 2px solid rgba(255, 255, 255, 0);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  25% {
    border: 2px solid rgba(255, 255, 255, 1);
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
  50% {
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  75% {
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  }
  100% {
    border: 2px solid rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  }
}

.dealershipLandingPageTopHalf button {
  width: 130px;
  height: 30px;
  border-radius: 50px;
  border: none;
  background-color: #0561fc;
  margin-top: 20px;
  /* color: #a3a3a3; */
  color: white;
  font-size: 16px;
  animation: lightningBorder 1s ease-in-out;
  animation-delay: 6s;
}

.dealershipLandingPageTopHalf button:hover {
  background-color: #007bff;
  color: #f0f0f0;
  cursor: pointer;
}

.DealershipLandingPageFAQ {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;

  padding-bottom: 80px;
}

.DealershipLandingPageFAQ h1 {
  font-size: 3rem; /* Large font size */
  font-weight: bold;
  color: white; /* Fallback color in case gradient doesn't render */
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center; /* Center the text */
  line-height: 1.2; /* Adjust line height for a clean look */
}

.DealershipLandingPageFAQ p {
  color: #e7e7e7;
  margin-top: 10px;
}

/**/

.DealershipLandingPageFAQBox {
  width: 90%;
  height: auto;

  margin-top: 20px;
}

.DealershipLandingPageFAQBoxAccordion {
  width: 100%;
}

.DealershipLandingPageFAQBoxItem {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  margin-bottom: 10px;
  /* height: 100px; */
  min-height: 75px;
  border-radius: 10px;
  padding: 40px 30px;
}

.DealershipLandingPageFAQBoxItemTitle {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.DealershipLandingPageFAQBoxItemTitle:hover {
  cursor: pointer;
}

.DealershipLandingPageFAQBoxItemTitle span {
  font-size: 30px;
}

.DealershipLandingPageFAQBoxContent {
  color: white;
  overflow: hidden;
  max-height: 0;
  margin-top: 10px;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.DealershipLandingPageFAQBoxContent a {
  text-decoration: underline;
  color: white;
}

.DealershipLandingPageFAQBoxContent.showBox {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

/**/

.imagesCollection-DLP {
  flex: 1;
}

.imagesCollection-DLP {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 520px;
  padding-left: 10px;
  padding-right: 10px;
  object-fit: cover;
}

.imagesCollection-DLP img {
  object-fit: cover;
}

.slide-DLP {
  border-radius: 0.5rem;
  /* box-shadow: 0px 0px 7px #666; */
  width: 100%;
  height: 100%;
}

.slide-hidden-DLP {
  display: none;
}

.arrow-DLP {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 2rem;
  height: 2rem;
  color: white;
}

.arrow-DLP:hover {
  cursor: pointer;
}

.arrow-left-DLP {
  left: 1rem;
}

.arrow-right-DLP {
  right: 1rem;
}

.indicators-DLP {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator-DLP {
  background-color: white;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive-DLP {
  background-color: grey;
}

.dealershipLandingPageCustomerView {
  margin-top: 25px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.dealershipLandingPageCustomerView h1 {
  font-size: 2.5rem; /* Large font size */
  font-weight: bold;
  color: #403f3f; /* Fallback color in case gradient doesn't render */
  background: linear-gradient(to bottom, #f0f0f0, #a3a3a3);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-align: center; /* Center the text */
  line-height: 1.2; /* Adjust line height for a clean look */
}

.dealershipLandingPageCustomerViewBackground {
  width: 280px;
  height: 575px;
  border: 1px solid rgba(255, 255, 255, 0.5); /* Thin white, slightly transparent border */
  border-radius: 15px; /* Rounded edges */
  background: rgba(
    255,
    255,
    255,
    0.1
  ); /* Semi-transparent background for foggy effect */
  backdrop-filter: blur(8px); /* Adds a blur effect to the background */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  color: #fff; /* White text for contrast */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  margin-top: 10px;
}

.imagesCollection-DLP-customer {
  flex: 1;
}

.imagesCollection-DLP-customer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 560px;
  padding-left: 10px;
  padding-right: 10px;
  object-fit: cover;
}

.imagesCollection-DLP-customer img {
  object-fit: cover;
}

.slide-DLP-customer {
  border-radius: 0.5rem;
  /* box-shadow: 0px 0px 7px #666; */
  width: 100%;
  height: 100%;
}

.slide-hidden-DLP-customer {
  display: none;
}

.arrow-DLP-customer {
  position: absolute;
  filter: drop-shadow(0px 0px 5px #555);
  width: 2rem;
  height: 2rem;
  color: white;
}

.arrow-DLP-customer:hover {
  cursor: pointer;
}

.arrow-left-DLP-customer {
  left: 1rem;
}

.arrow-right-DLP-customer {
  right: 1rem;
}

.indicators-DLP-customer {
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.indicator-DLP-customer {
  background-color: white;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive-DLP-customer {
  background-color: grey;
}

.dealershipLandingPageTable {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.dealershipLandingPageTable h1 {
  font-size: 2.5rem; /* Large font size */
  font-weight: bold;
  color: white; /* Fallback color in case gradient doesn't render */
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center; /* Center the text */
  line-height: 1.2; /* Adjust line height for a clean look */
}

.dealershipLandingPageTableOfFeatures {
  width: 70%;
  height: auto;

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dealershipLandingPageTableOfFeaturesBottom {
  width: 100%;
  height: 100%;
}

.dealershipLandingPageTableOfFeaturesTop {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.dealershipLandingPageTableOfFeaturesBottom2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.dealershipLandingPageTableOfFeaturesBottom2 > div {
  width: 50%;
  height: 100%;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  padding-top: 10px;
}

.dealershipLandingPageTableOfFeaturesBottom2 li {
  margin-left: 10px;
}

.dealershipLandingPageTableOfFeaturesTop > div {
  width: 50%;
  height: 100%;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dealershipLandingPageTableOfFeaturesTopHeadingsLeft {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.dealershipLandingPageTableOfFeaturesTopContentLeft {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.dealershipLandingPageYoutube {
  margin-top: 50px;
  width: 70%;

  max-width: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dealershipLandingPageYoutube iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.dealershipLandingPageYoutube h2 {
  font-size: 2.5rem; /* Large font size */
  font-weight: bold;
  color: white; /* Fallback color in case gradient doesn't render */
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center; /* Center the text */
  line-height: 1.2; /* Adjust line height for a clean look */
}

@media (max-width: 1270px) {
  .dealershipLandingPageTopHalf h1 {
    font-size: 3rem; /* Smaller font size when the screen width is <= 1270px */
  }

  .dealershipLandingPageTopHalf p {
    text-align: center;
  }

  .dealershipLandingPageTopHalf {
    height: auto;
  }

  .DealershipLandingPageFAQ h1 {
    font-size: 2.5rem;
  }

  .dealershipLandingPageYoutube h2 {
    font-size: 2.1rem;
  }

  .DealershipLandingPageFAQ {
    text-align: center;
  }

  .DealershipLandingPageFAQ p {
    padding-left: 20px;
    padding-right: 20px;
  }

  .DealershipLandingPageFAQBoxItemTitle h2 {
    font-size: 1.5rem;
  }

  .dealershipLandingPageTableOfFeaturesTop > div h2 {
    font-size: 1.1rem;
  }

  .dealershipLandingPageTable h1 {
    font-size: 1.6rem;
  }
}

@media (max-width: 952px) {
  .dealershipLandingPageTopHalf h1 {
    font-size: 2.5rem; /* Smaller font size when the screen width is <= 1270px */
  }

  .dealershipLandingPageTopHalf p {
    text-align: center;
  }

  /* .dealershipLandingPageTopHalf {
    margin-bottom: 40px;
  } */

  .dealershipLandingPageTopHalf {
    height: auto;
    width: auto;
    padding-left: 25px;
    padding-right: 25px;
  }

  .dealershipLandingPageBottomHalf {
    height: auto;
    width: 100%;
  }

  .imagesCollection-DLP {
    height: 100%;
    width: 100%;
  }

  .DealershipLandingPageFAQBoxItemTitle h2 {
    font-size: 1.25rem;
    margin-right: 20px;
  }

  .dealershipLandingPageMiddle h1 {
    font-size: 2rem; /* Large font size */
  }

  .dealershipLandingPageCustomerView h1 {
    font-size: 2rem; /* Large font size */
  }

  .dealershipLandingPageTableOfFeaturesTop > div h2 {
    font-size: 0.8rem;
  }

  .dealershipLandingPageTableOfFeatures {
    width: 90%;
  }

  .dealershipLandingPageTableOfFeaturesBottom2 > div li {
    font-size: 10px;
  }
}

/* Dashboard Customer Order Page 2 */

.dashboardCustomerOrderPage2 {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboardCustomerOrderPage2OrderAndBack {
  width: 95%;
  max-width: 1380px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboardCustomerOrderPage2OrderAndBack button {
  display: flex;
  align-items: center;
  background-color: transparent;
  background-image: none;
  border: none;
  text-shadow: none;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  color: black;
  font-size: 16px;
  margin-right: 10px;
}

.dashboardCustomerOrderPage2OrderAndBack h2 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
}

.dashboardCustomerOrderPage2Container {
  width: 95%;
  max-width: 1380px;
  min-height: 900px;
  border-radius: 17px;
  background: #fff;
  margin-top: 20px;
  padding-bottom: 30px;
}

.dashboardCustomerOrderPage2ContainerFinancial {
  width: 100%;
  min-height: 200px;
  padding-left: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboardCustomerOrderPage2ContainerFinancialImg {
  width: 238px;
  height: 160px;
  padding-top: 5px;
}

.dashboardCustomerOrderPage2ContainerFinancialImg img {
  width: 238px;
  height: 160px;
  flex-shrink: 0;
  border-radius: 7px 7px 0px 0px;
  object-fit: cover;
}

.dashboardCustomerOrderPage2ContainerFinancialTable {
  width: 79%;
  height: 190px;

  margin-top: 30px;
}

.dashboardCustomerOrderPage2CustomerDetails {
  width: 100%;
  min-height: 250px;
  padding-left: 20px;

  padding-bottom: 10px;
}

.dashboardCustomerOrderPage2CustomerDetailsTopHalf {
  width: 100%;

  min-height: 75px;
}

.dashboardCustomerOrderPage2CustomerDetailsTopHalf h4 {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
}

.dashboardCustomerOrderPage2CustomerDetailsTopHalf p {
  color: #000;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 10px;
}

.dashboardCustomerOrderPage2CustomerDetailsBottomHalf {
  width: 100%;

  min-height: 175px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
  gap: 16px; /* Optional: Adds space between columns */
  width: 100%; /* Ensures the container spans full width */
}

.dashboardCustomerOrderPage2CustomerDetailsBottomHalfColumn {
  min-width: 100px;
}

.dashboardCustomerOrderPage2CustomerDetailsBottomHalfColumn p {
  margin-bottom: 10px; /* Add 10px bottom margin to each p element */
}

.dashboardCustomerOrderPage2CustomerDetailsBottomHalfColumn h5 {
  color: #0561fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.dashboardCustomerOrderPage2BookingProgress {
  width: 100%;
  height: 90px;
}

.dashboardCustomerOrderPage2BookingProgress h4 {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-left: 20px;
}

.dashboardCustomerOrderPage2BookingProgress p {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  margin-top: 14px;
  margin-left: 20px;
}

.dashboardCustomer2OrderPageDurationDetails {
  width: 100%;
  height: auto;

  padding-left: 20px;
  padding-bottom: 20px;
}

.dashboardCustomer2OrderPageDurationDetails h4 {
  color: #93a3ab;
  font-family: EB Garamond;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  margin-bottom: 10px;
}

/* Media query for smaller screens */
@media (max-width: 786px) {
  .dashboardCustomerOrderPage2ContainerFinancial {
    flex-direction: column; /* Stack children vertically */
    align-items: stretch; /* Ensures children take full width if needed */
    gap: 20px; /* Optional: Adds space between stacked items */
  }

  .dashboardCustomerOrderPage2ContainerFinancialImg {
    margin: 0 auto; /* Centers the image horizontally */
  }

  .dashboardCustomerOrderPage2ContainerFinancialTable {
    width: 100%; /* Makes the table take full width */
    font-size: 14px; /* Adjusts the font size of the table */
  }

  .dashboardCustomerOrderPage2ContainerFinancialTable td,
  .dashboardCustomerOrderPage2ContainerFinancialTable th {
    font-size: 12px; /* Adjusts font size for table cells and headers */
  }

  .dashboardCustomerOrderPage2CustomerDetailsBottomHalf {
    grid-template-columns: 1fr; /* Stacks the columns vertically */
    gap: 20px; /* Optional: Adds more space between stacked items */
  }
}

/* Media query for smaller screens */
@media (max-width: 430px) {
  .dashboardCustomerOrderPage2ContainerFinancialTable {
    font-size: 12px; /* Adjusts the font size of the table */
  }

  .dashboardCustomerOrderPage2ContainerFinancialTable td,
  .dashboardCustomerOrderPage2ContainerFinancialTable th {
    font-size: 9.5px; /* Adjusts font size for table cells and headers */
  }
}

@media (max-width: 390px) {
  .dashboardCustomer2OrderPageDurationDetails td,
  .dashboardCustomer2OrderPageDurationDetails th {
    font-size: 10px;
  }
}
